import { addPassiveEventListener as oe, buildSvg as ae } from "./dom-utils.js";
import { KeyCodes as mt } from "./key-codes.js";
export function createCloseButton(t, o, e, n = "ltr") {
  const r = document.createElement("button");
  r.setAttribute("aria-label", t),
    r.setAttribute("tabindex", "0"),
    r.setAttribute("role", "button"),
    (r.dir = n),
    oe(r, "touchstart"),
    (r.className = "ab-close-button");
  const l = ae(
    "0 0 15 15",
    "M15 1.5L13.5 0l-6 6-6-6L0 1.5l6 6-6 6L1.5 15l6-6 6 6 1.5-1.5-6-6 6-6z",
    o,
  );
  return (
    r.appendChild(l),
    r.addEventListener("keydown", (t) => {
      (t.keyCode !== mt.Fo && t.keyCode !== mt.To) ||
        (e(), t.stopPropagation());
    }),
    (r.onclick = (t) => {
      e(), t.stopPropagation();
    }),
    r
  );
}
