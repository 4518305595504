import Card from "./card.js";
export default class ClassicCard extends Card {
  constructor(s, t, i, h, r, c, e, a, o, d, l, n, u, p, f, m) {
    super(s, t, i, h, r, c, e, a, o, d, l, n, u, p, f, m),
      (this._ = "ab-classic-card"),
      (this.S = !0);
  }
  Y() {
    const s = {};
    return (
      (s[Card.tt.Z] = Card.it.Mt),
      (s[Card.tt.ht] = this.id),
      (s[Card.tt.et] = this.viewed),
      (s[Card.tt.rt] = this.title),
      (s[Card.tt.ot] = this.imageUrl),
      (s[Card.tt.ct] = this.description),
      (s[Card.tt.nt] = this.updated),
      (s[Card.tt.dt] = this.created),
      (s[Card.tt.ut] = this.categories),
      (s[Card.tt.lt] = this.expiresAt),
      (s[Card.tt.URL] = this.url),
      (s[Card.tt.ft] = this.linkText),
      (s[Card.tt.xt] = this.aspectRatio),
      (s[Card.tt.bt] = this.extras),
      (s[Card.tt.gt] = this.pinned),
      (s[Card.tt.jt] = this.dismissible),
      (s[Card.tt.zt] = this.clicked),
      (s[Card.tt.kt] = this.test),
      s
    );
  }
}
