import { logger as r } from "../../shared-lib/index.js";
import e from "../managers/braze-instance.js";
import ir from "./feature-flags-provider-factory.js";
export function getAllFeatureFlags() {
  if (!e.X()) return;
  const t = [],
    n = e.ir();
  if (n && !n.ci()) return r.info("Feature flags are not enabled."), t;
  const o = ir.rr().mi();
  for (const r in o) t.push(o[r]);
  return t;
}
