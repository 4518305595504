import e, { OPTIONS as L } from "../managers/braze-instance.js";
import X from "../util/browser-detector.js";
import nr from "./l10n-manager.js";
const ue = {
  t: !1,
  i: null,
  m: () => {
    if ((ue.o(), !ue.i)) {
      let r = X.language,
        t = !1;
      e.nn(L.zn) && ((r = e.nn(L.zn)), (t = !0)), (ue.i = new nr(r, t));
    }
    return ue.i;
  },
  o: () => {
    ue.t || (e.g(ue), (ue.t = !0));
  },
  destroy: () => {
    (ue.i = null), (ue.t = !1);
  },
};
export default ue;
