import K from "../managers/braze-instance.js";
import BrazeSdkMetadata from "./braze-sdk-metadata.js";
import {
  isArray as j,
  validateValueIsFromEnum as J,
} from "../util/code-utils.js";
import { logger as r } from "../../shared-lib/index.js";
export function addSdkMetadata(a) {
  if (!K.X()) return;
  const t = K.nr();
  if (t) {
    if (!j(a))
      return (
        r.error("Cannot set SDK metadata because metadata is not an array."), !1
      );
    for (const t of a)
      if (
        !J(
          BrazeSdkMetadata,
          t,
          "sdkMetadata contained an invalid value.",
          "BrazeSdkMetadata",
        )
      )
        return !1;
    return t.addSdkMetadata(a), !0;
  }
}
