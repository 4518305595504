import { Card } from "./models/index.js";
import n from "./card-manager-factory.js";
import e from "../managers/braze-instance.js";
import { isArray as j } from "../util/code-utils.js";
import { MUST_BE_CARD_WARNING_SUFFIX as f } from "../common/constants.js";
import { logger as r } from "../../shared-lib/index.js";
export function logCardImpressions(o, s) {
  if (!e.X()) return !1;
  if (!j(o)) return r.error("cards must be an array"), !1;
  for (const n of o)
    if (!(n instanceof Card)) return r.error(`Each card in cards ${f}`), !1;
  return n.m().G(o, s).L;
}
