import Card from "./card.js";
export default class CaptionedImage extends Card {
  constructor(t, s, i, h, e, r, a, o, c, n, d, p, u, l, m, f) {
    super(t, s, i, h, e, r, a, o, c, n, d, p, u, l, m, f),
      (this._ = "ab-captioned-image"),
      (this.S = !0),
      (this.test = !1);
  }
  Y() {
    const t = {};
    return (
      (t[Card.tt.Z] = Card.it.st),
      (t[Card.tt.ht] = this.id),
      (t[Card.tt.et] = this.viewed),
      (t[Card.tt.rt] = this.title),
      (t[Card.tt.ot] = this.imageUrl),
      (t[Card.tt.ct] = this.description),
      (t[Card.tt.nt] = this.updated),
      (t[Card.tt.dt] = this.created),
      (t[Card.tt.ut] = this.categories),
      (t[Card.tt.lt] = this.expiresAt),
      (t[Card.tt.URL] = this.url),
      (t[Card.tt.ft] = this.linkText),
      (t[Card.tt.xt] = this.aspectRatio),
      (t[Card.tt.bt] = this.extras),
      (t[Card.tt.gt] = this.pinned),
      (t[Card.tt.jt] = this.dismissible),
      (t[Card.tt.zt] = this.clicked),
      (t[Card.tt.kt] = this.test),
      t
    );
  }
}
