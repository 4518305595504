import y from "../common/base-provider.js";
import { newInAppMessageFromJson as pt } from "../InAppMessage/in-app-message-factory.js";
import ve from "../models/braze-event.js";
import {
  isArray as j,
  isEqual as ii,
  validateValueIsFromEnum as J,
} from "../util/code-utils.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import wt from "../InAppMessage/models/templated-in-app-message.js";
import gt from "./models/trigger.js";
import tt from "./models/trigger-events.js";
import { logger as r } from "../../shared-lib/index.js";
export default class gr extends y {
  constructor(t, i, s, e, r) {
    super(),
      (this.tg = t),
      (this.Jt = i),
      (this.u = s),
      (this.zi = e),
      (this.ig = r),
      (this.tg = t),
      (this.Jt = i),
      (this.u = s),
      (this.zi = e),
      (this.ig = r),
      (this.sg = []),
      (this.eg = []),
      (this.hg = null),
      (this.ng = {}),
      (this.og = {}),
      (this.triggers = []),
      (this.lg = 0),
      this.ag(),
      this.gg();
  }
  fg() {
    if (this.u) {
      (this.hg = this.u.j(o.C.iE) || this.hg),
        (this.ng = this.u.j(o.C.EE) || this.ng),
        (this.og = this.u.j(o.C.aE) || this.og);
      for (let t = 0; t < this.triggers.length; t++) {
        const i = this.triggers[t];
        i.id && null != this.og[i.id] && i.ad(this.og[i.id]);
      }
    }
  }
  ag() {
    if (!this.u) return;
    this.lg = this.u.j(o.C.oE) || 0;
    const t = this.u.j(o.C.rE) || [],
      i = [];
    for (let s = 0; s < t.length; s++) i.push(gt.qn(t[s]));
    (this.triggers = i), this.fg();
  }
  gg() {
    const t = this,
      i = function (i, s, e, r, h) {
        return function () {
          t.cg(i, s, e, r, h);
        };
      },
      s = {};
    for (const t of this.triggers) t.id && (s[t.id] = t);
    let e = !1;
    for (let t = 0; t < this.triggers.length; t++) {
      const r = this.triggers[t];
      if (r.id && null != this.ng[r.id]) {
        const t = this.ng[r.id],
          h = [];
        for (let e = 0; e < t.length; e++) {
          const n = t[e],
            o = r.ud(n.Er || 0);
          if (o > 0) {
            let t, e;
            h.push(n),
              null != n.ug && (t = n.ug),
              null != n.dg && ve.RE(n.dg) && (e = ve.qn(n.dg));
            const l = [];
            if (n.pg && j(n.pg))
              for (let t = 0; t < n.pg.length; t++) {
                const i = s[n.pg[t]];
                null != i && l.push(i);
              }
            this.eg.push(window.setTimeout(i(r, n.Er || 0, t, e, l), o));
          }
        }
        this.ng[r.id].length > h.length &&
          ((this.ng[r.id] = h),
          (e = !0),
          0 === this.ng[r.id].length && delete this.ng[r.id]);
      }
    }
    e && this.u && this.u.k(o.C.EE, this.ng);
  }
  mg() {
    if (!this.u) return;
    const t = [];
    for (let i = 0; i < this.triggers.length; i++) t.push(this.triggers[i].Y());
    (this.lg = new Date().valueOf()),
      this.u.k(o.C.rE, t),
      this.u.k(o.C.oE, this.lg);
  }
  bg() {
    if (!this.u) return;
    (this.u.j(o.C.oE) || 0) > this.lg ? this.ag() : this.fg();
  }
  Ns(t) {
    let i = !1;
    if (null != t && t.triggers) {
      this.ig.Lr(), this.fg();
      const s = {},
        e = {};
      this.triggers = [];
      for (let r = 0; r < t.triggers.length; r++) {
        const h = gt.fromJson(t.triggers[r]);
        if (h) {
          h.id &&
            null != this.og[h.id] &&
            (h.ad(this.og[h.id]), (s[h.id] = this.og[h.id])),
            h.id && null != this.ng[h.id] && (e[h.id] = this.ng[h.id]);
          for (let t = 0; t < h.sd.length; t++)
            if (h.sd[t].sc(tt.kt, null)) {
              i = !0;
              break;
            }
          this.triggers.push(h);
        }
      }
      ii(this.og, s) || ((this.og = s), this.u && this.u.k(o.C.aE, this.og)),
        ii(this.ng, e) || ((this.ng = e), this.u && this.u.k(o.C.EE, this.ng)),
        this.mg(),
        i &&
          (r.info("Trigger with test condition found, firing test."),
          this.fe(tt.kt)),
        this.fe(tt.OPEN);
      const h = this.sg;
      let n;
      this.sg = [];
      for (let t = 0; t < h.length; t++)
        (n = Array.prototype.slice.call(h[t])), this.fe(...n);
    }
  }
  cg(t, i, s, e, h) {
    const n = (e) => {
        this.fg();
        const h = new Date().valueOf();
        t.dd(i) ||
          (!1 === navigator.onLine && t.type === gt.Br.Tr && e.imageUrl
            ? r.info(
                `Not showing ${t.type} trigger action ${t.id} due to offline state.`,
              )
            : t.nd(h) && this.wg(t, h, s)
            ? 0 === this.Jt.ic()
              ? r.info(
                  `Not displaying trigger ${t.id} because neither automaticallyShowInAppMessages() nor subscribeToInAppMessage() were called.`,
                )
              : (this.Jt.Dt([e]), this.yg(t, h))
            : r.info(
                `Not displaying trigger ${t.id} because display time fell outside of the acceptable time window.`,
              ));
      },
      o = () => {
        this.fg();
        const n = h.pop();
        if (null != n)
          if ((this.Tg(n, i, s, e, h), n.dd(i))) {
            let t = `Server aborted in-app message display, but the timeout on fallback trigger ${n.id} has already elapsed.`;
            h.length > 0 && (t += " Continuing to fall back."), r.info(t), o();
          } else {
            r.info(
              `Server aborted in-app message display. Falling back to lower priority ${n.type} trigger action ${t.id}.`,
            );
            const o = 1e3 * n.ed - (new Date().valueOf() - i);
            o > 0
              ? this.eg.push(
                  window.setTimeout(() => {
                    this.cg(n, i, s, e, h);
                  }, o),
                )
              : this.cg(n, i, s, e, h);
          }
      };
    let l, a, g;
    switch (t.type) {
      case gt.Br.Tr:
        if (((l = pt(t.data)), null == l)) {
          r.error(
            `Could not parse trigger data for trigger ${t.id}, ignoring.`,
          );
          break;
        }
        if (((a = this.ig.Ki(l)), a)) {
          r.error(a), o();
          break;
        }
        n(l);
        break;
      case gt.Br.md:
        if (((g = wt.fromJson(t.data, n, o, i, t.Gr || 0)), null == g)) {
          r.error(
            `Could not parse trigger data for trigger ${t.id}, ignoring.`,
          );
          break;
        }
        this.ig.Wi(g, s, e);
        break;
      default:
        r.error(`Trigger ${t.id} was of unexpected type ${t.type}, ignoring.`);
    }
  }
  fe(t, i = null, s) {
    if (!J(tt, t, "Cannot fire trigger action.", "TriggerEvents")) return;
    if (this.zi && this.zi.zu())
      return (
        r.info(
          "Trigger sync is currently in progress, awaiting sync completion before firing trigger event.",
        ),
        void this.sg.push(arguments)
      );
    this.bg();
    const e = new Date().valueOf(),
      h = e - (this.hg || 0);
    let n = !0,
      o = !0;
    const l = [];
    for (let s = 0; s < this.triggers.length; s++) {
      const r = this.triggers[s],
        h = e + 1e3 * r.ed;
      if (
        r.nd(h) &&
        (null == r.startTime || r.startTime.valueOf() <= e) &&
        (null == r.endTime || r.endTime.valueOf() >= e)
      ) {
        let s = !1;
        for (let e = 0; e < r.sd.length; e++)
          if (r.sd[e].sc(t, i)) {
            s = !0;
            break;
          }
        s && ((n = !1), this.wg(r, h, t) && ((o = !1), l.push(r)));
      }
    }
    if (n)
      return void r.info(
        `Trigger event ${t} did not match any trigger conditions.`,
      );
    if (o)
      return void r.info(
        `Ignoring ${t} trigger event because a trigger was displayed ${
          h / 1e3
        }s ago.`,
      );
    l.sort((t, i) => t.priority - i.priority);
    const a = l.pop();
    null != a &&
      (r.info(
        `Firing ${a.type} trigger action ${a.id} from trigger event ${t}.`,
      ),
      this.Tg(a, e, t, s, l),
      0 === a.ed
        ? this.cg(a, e, t, s, l)
        : this.eg.push(
            window.setTimeout(() => {
              this.cg(a, e, t, s, l);
            }, 1e3 * a.ed),
          ));
  }
  changeUser(t = !1) {
    if (((this.triggers = []), this.u && this.u.ti(o.C.rE), !t)) {
      (this.sg = []), (this.hg = null), (this.og = {}), (this.ng = {});
      for (let t = 0; t < this.eg.length; t++) clearTimeout(this.eg[t]);
      (this.eg = []),
        this.u && (this.u.ti(o.C.iE), this.u.ti(o.C.aE), this.u.ti(o.C.EE));
    }
  }
  clearData() {
    (this.triggers = []), (this.hg = null), (this.og = {}), (this.ng = {});
    for (let t = 0; t < this.eg.length; t++) clearTimeout(this.eg[t]);
    this.eg = [];
  }
  wg(t, i, s) {
    if (null == this.hg) return !0;
    if (s === tt.kt)
      return (
        r.info(
          "Ignoring minimum interval between trigger because it is a test type.",
        ),
        !0
      );
    let e = t.hd;
    return null == e && (e = this.tg), i - this.hg >= 1e3 * e;
  }
  Tg(t, i, s, e, r) {
    this.fg(), t.id && (this.ng[t.id] = this.ng[t.id] || []);
    const h = {};
    let n;
    (h.Er = i), (h.ug = s), null != e && (n = e.Y()), (h.dg = n);
    const l = [];
    for (const t of r) t.id && l.push(t.id);
    (h.pg = l),
      t.id && this.ng[t.id].push(h),
      this.u && this.u.k(o.C.EE, this.ng);
  }
  yg(t, i) {
    this.fg(),
      t.ad(i),
      (this.hg = i),
      t.id && (this.og[t.id] = i),
      this.u && (this.u.k(o.C.iE, i), this.u.k(o.C.aE, this.og));
  }
}
