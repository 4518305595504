import {
  addPassiveEventListener as oe,
  buildSvg as ae,
  detectSwipe as c,
  DIRECTIONS as m,
} from "../../util/dom-utils.js";
import { createCloseButton as d } from "../../util/component-utils.js";
import { isTransparent as ne, toRgba as ie } from "../../util/color-utils.js";
import FullScreenMessage from "../models/full-screen-message.js";
import HtmlMessage from "../models/html-message.js";
import InAppMessage from "../models/in-app-message.js";
import ModalMessage from "../models/modal-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
import { logInAppMessageClick } from "../log-in-app-message-click.js";
import { _handleBrazeAction as u } from "../../Core/handle-braze-action.js";
import dt from "./html-message-to-html.js";
import le from "./modal-utils.js";
import { logger as r, Guid as p } from "../../../shared-lib/index.js";
export default function ce(e, o, a, t, n, s, i, l = document.body, b = "ltr") {
  if (((e.ke = document.activeElement), e instanceof HtmlMessage))
    return dt(e, o, a, n, s, i);
  const g = (function (e, o, a, t, n, s, i = document.body, m = "ltr") {
    let l = null;
    const c = document.createElement("div");
    (c.dir = m),
      (c.className = "ab-in-app-message ab-start-hidden ab-background"),
      s && (c.style.zIndex = (s + 1).toString()),
      e.$e() &&
        ((c.className += " ab-modal-interactions"),
        c.setAttribute("tabindex", "-1")),
      e.ve() ||
        ((c.style.color = ie(e.textColor)),
        (c.style.backgroundColor = ie(e.backgroundColor)),
        ne(e.backgroundColor) && (c.className += " ab-no-shadow"));
    const b = () => {
        -1 !== c.className.indexOf("ab-start-hidden") &&
          ((c.className = c.className.replace("ab-start-hidden", "")),
          document.querySelectorAll(".ab-iam-img-loading").length > 0
            ? t(
                `Cannot show in-app message ${e.message} because another message is being shown.`,
                InAppMessage.Le.ze,
              )
            : a(c));
      },
      g = (o = !0) => {
        let a = document.querySelectorAll(".ab-iam-root");
        (a && 0 !== a.length) || (a = i.querySelectorAll(".ab-iam-root")),
          a &&
            a.length > 0 &&
            (a[0].classList.remove("ab-iam-img-loading"),
            l && (clearTimeout(l), (l = null)),
            o
              ? b()
              : r.error(
                  `Cannot show in-app message ${e.message} because the image failed to load.`,
                ));
      };
    e.imageStyle === InAppMessage.ImageStyle.GRAPHIC &&
      (c.className += " graphic"),
      e.orientation === InAppMessage.Orientation.LANDSCAPE &&
        (c.className += " landscape"),
      null != e.buttons &&
        0 === e.buttons.length &&
        (e.clickAction !== InAppMessage.ClickAction.NONE &&
          (c.className += " ab-clickable"),
        (c.onclick = (a) => (
          e.Cr(c, () => {
            logInAppMessageClick(e),
              e.clickAction === InAppMessage.ClickAction.URI
                ? u(
                    e.uri || "",
                    n || e.openTarget === InAppMessage.OpenTarget.BLANK,
                    a,
                  )
                : e.clickAction === InAppMessage.ClickAction.NEWS_FEED && o();
          }),
          a.stopPropagation(),
          !1
        )));
    const f = d(
      "Close Message",
      e.ve() ? void 0 : ie(e.closeButtonColor),
      () => {
        e.Cr(c);
      },
      m,
    );
    c.appendChild(f), s && (f.style.zIndex = (s + 2).toString());
    const h = document.createElement("div");
    (h.className = "ab-message-text"), (h.dir = m);
    const j = (e.messageAlignment || e.qe).toLowerCase();
    h.className += " " + j + "-aligned";
    let w = !1;
    const v = document.createElement("div");
    if (((v.className = "ab-image-area"), e.imageUrl)) {
      if (e.cropType === InAppMessage.CropType.CENTER_CROP) {
        const o = document.createElement("span");
        (o.className = "ab-center-cropped-img"),
          (o.style.backgroundImage = "url(" + e.imageUrl + ")"),
          o.setAttribute("role", "img"),
          o.setAttribute("aria-label", "Modal Image"),
          e.Ae(o),
          v.appendChild(o);
      } else {
        const o = document.createElement("img");
        if (
          (o.setAttribute("src", e.imageUrl),
          e.Ae(o),
          0 === document.querySelectorAll(".ab-iam-img-loading").length)
        ) {
          w = !0;
          const e = document.querySelectorAll(".ab-iam-root");
          e && e.length > 0 && e[0].classList.add("ab-iam-img-loading"),
            (l = window.setTimeout(() => {
              g(!1);
            }, 6e4)),
            (o.onload = () => {
              g();
            }),
            (o.onerror = () => {
              g(!1);
            });
        }
        v.appendChild(o);
      }
      c.appendChild(v), (h.className += " ab-with-image");
    } else if (e.icon) {
      v.className += " ab-icon-area";
      const o = document.createElement("span");
      (o.className = "ab-icon"),
        e.ve() ||
          ((o.style.backgroundColor = ie(e.iconBackgroundColor)),
          (o.style.color = ie(e.iconColor)));
      const a = document.createElement("i");
      (a.className = "fa"),
        a.appendChild(document.createTextNode(e.icon)),
        a.setAttribute("aria-hidden", "true"),
        o.appendChild(a),
        v.appendChild(o),
        c.appendChild(v),
        (h.className += " ab-with-icon");
    }
    if ((oe(h, "touchstart"), e.header && e.header.length > 0)) {
      const o = document.createElement("h1");
      (o.className = "ab-message-header"), (e.De = p.W()), (o.id = e.De);
      const a = (
        e.headerAlignment || InAppMessage.TextAlignment.CENTER
      ).toLowerCase();
      (o.className += " " + a + "-aligned"),
        e.ve() || (o.style.color = ie(e.headerTextColor)),
        o.appendChild(document.createTextNode(e.header)),
        h.appendChild(o);
    }
    return h.appendChild(e.Ge()), c.appendChild(h), w || b(), (e.Re = c), c;
  })(e, o, a, t, n, s, l, b);
  if (e instanceof FullScreenMessage || e instanceof ModalMessage) {
    const a = e instanceof FullScreenMessage ? "ab-fullscreen" : "ab-modal";
    (g.className += ` ${a} ab-centered`),
      le.He(e, o, g, n),
      le.Je(g),
      le.Ke(e.De, g);
  } else if (e instanceof SlideUpMessage) {
    g.className += " ab-slideup";
    const o = g.getElementsByClassName("ab-close-button")[0];
    if (null != o) {
      const a = ae(
        "0 0 11.38 19.44",
        "M11.38 9.72l-9.33 9.72L0 17.3l7.27-7.58L0 2.14 2.05 0l9.33 9.72z",
        e.ve() ? void 0 : ie(e.closeButtonColor),
      );
      a.setAttribute("class", "ab-chevron"), o.appendChild(a);
    }
    let a, t;
    c(g, m.U, (e) => {
      (g.className += " ab-swiped-left"),
        null != o && null != o.onclick && o.onclick(e);
    }),
      c(g, m.V, (e) => {
        (g.className += " ab-swiped-right"),
          null != o && null != o.onclick && o.onclick(e);
      }),
      e.slideFrom === InAppMessage.SlideFrom.TOP
        ? ((a = m.Oe), (t = " ab-swiped-up"))
        : ((a = m.Qe), (t = " ab-swiped-down")),
      c(g, a, (e) => {
        (g.className += t), null != o && null != o.onclick && o.onclick(e);
      });
  }
  return g;
}
