const ei = {
  Tn: function (t) {
    const r = (t + "=".repeat((4 - (t.length % 4)) % 4))
        .replace(/\-/g, "+")
        .replace(/_/g, "/"),
      n = atob(r),
      o = new Uint8Array(n.length);
    for (let t = 0; t < n.length; ++t) o[t] = n.charCodeAt(t);
    return o;
  },
};
export default ei;
