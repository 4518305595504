import e from "../managers/braze-instance.js";
import { getByteLength as O } from "../util/string-utils.js";
import { logger as r } from "../../shared-lib/index.js";
import { User } from "../User/index.js";
import { validateStandardString as P } from "../util/validation-utils.js";
export function changeUser(i, t) {
  if (!e.X()) return;
  if (null == i || 0 === i.length || i != i)
    return void r.error("changeUser requires a non-empty userId.");
  if (O(i) > User.lr)
    return void r.error(
      `Rejected user id "${i}" because it is longer than ${User.lr} bytes.`,
    );
  if (null != t && !P(t, "set signature for new user", "signature")) return;
  const n = e.cr();
  n && n.changeUser(i.toString(), e.gr(), t);
}
