import se from "../InAppMessage/in-app-message-manager-factory.js";
import e, { OPTIONS as L } from "../managers/braze-instance.js";
import gr from "./triggers-provider.js";
export const TriggersProviderFactory = {
  t: !1,
  provider: null,
  rr: () => (
    TriggersProviderFactory.o(),
    TriggersProviderFactory.provider || TriggersProviderFactory.rg(),
    TriggersProviderFactory.provider
  ),
  rg: () => {
    if (!TriggersProviderFactory.provider) {
      const r = e.nn(L.Oo);
      (TriggersProviderFactory.provider = new gr(
        null != r ? r : 30,
        se.m().Ze(),
        e.l(),
        e.cr(),
        se.m(),
      )),
        e.ar(TriggersProviderFactory.provider);
    }
  },
  o: () => {
    TriggersProviderFactory.t ||
      (TriggersProviderFactory.rg(),
      e.g(TriggersProviderFactory),
      (TriggersProviderFactory.t = !0));
  },
  destroy: () => {
    (TriggersProviderFactory.provider = null), (TriggersProviderFactory.t = !1);
  },
};
