import e from "../managers/braze-instance.js";
import { logger as r } from "../../shared-lib/index.js";
export function getDeviceId(t) {
  if (!e.X()) return;
  const i = e.te();
  if (!i) return;
  const n = i.ce().id;
  if ("function" != typeof t) return n;
  r.warn(
    "The callback for getDeviceId is deprecated. You can access its return value directly instead (e.g. `const id = braze.getDeviceId()`)",
  ),
    t(n);
}
