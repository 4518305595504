import { logger as r } from "../../shared-lib/index.js";
export function getByteLength(t) {
  let e = t.length;
  for (let n = t.length - 1; n >= 0; n--) {
    const r = t.charCodeAt(n);
    r > 127 && r <= 2047 ? e++ : r > 2047 && r <= 65535 && (e += 2),
      r >= 56320 && r <= 57343 && n--;
  }
  return e;
}
export function decodeBrazeActions(t) {
  try {
    t = t.replace(/-/g, "+").replace(/_/g, "/");
    const e = window.atob(t),
      n = new Uint8Array(e.length);
    for (let t = 0; t < e.length; t++) n[t] = e.charCodeAt(t);
    const r = new Uint16Array(n.buffer);
    return String.fromCharCode(...r);
  } catch (t) {
    return r.error("Unable to decode Base64: " + t), null;
  }
}
