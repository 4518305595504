export default class ns {
  constructor(t) {
    (this.productId = t), (this.productId = t);
  }
  Jl(t) {
    return null == this.productId || t[0] === this.productId;
  }
  static fromJson(t) {
    return new ns(t ? t.product_id : null);
  }
  Y() {
    return this.productId;
  }
}
