import e from "../managers/braze-instance.js";
import s from "../common/event-logger.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import tt from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as et } from "../triggers/triggers-provider-factory.js";
import {
  validateCustomProperties as ot,
  validateCustomString as rt,
} from "../util/validation-utils.js";
import { LOG_CUSTOM_EVENT_STRING as nt } from "../common/constants.js";
export function logCustomEvent(t, o) {
  if (!e.X()) return !1;
  if (null == t || t.length <= 0)
    return (
      r.error(
        `logCustomEvent requires a non-empty eventName, got "${t}". Ignoring event.`,
      ),
      !1
    );
  if (!rt(t, "log custom event", "the event name")) return !1;
  const [n, m] = ot(
    o,
    nt,
    "eventProperties",
    `log custom event "${t}"`,
    "event",
  );
  if (!n) return !1;
  const g = e.ir();
  if (g && g.me(t))
    return r.info(`Custom Event "${t}" is blocklisted, ignoring.`), !1;
  const f = s.q(i.CustomEvent, { n: t, p: m });
  if (f.L) {
    r.info(`Logged custom event "${t}".`);
    for (const e of f.ge) et.rr().fe(tt.ue, [t, o], e);
  }
  return f.L;
}
