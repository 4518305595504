import e from "../managers/braze-instance.js";
import { logger as r, IndexedDBAdapter as A } from "../../shared-lib/index.js";
import tt from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as et } from "../triggers/triggers-provider-factory.js";
export function openSession() {
  if (!e.X()) return;
  const i = e.cr();
  if (!i) return;
  i.openSession();
  const t = A.Yt.Qt,
    o = new A(t, r);
  o.jr(t.ss.hr, (n, e) => {
    const s = e.lastClick,
      c = e.trackingString;
    r.info(`Firing push click trigger from ${c} push click at ${s}`);
    const g = i.kr(s, c),
      f = function () {
        et.rr().fe(tt.vr, [c], g);
      };
    i.$r(f, f), o.re(t.ss.hr, n);
  }),
    o.Zt(t.ss.wr, function (r) {
      i.yr(r);
    });
}
