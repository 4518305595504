import { isArray as j } from "./code-utils.js";
export default class ge {
  constructor() {}
  ef() {}
  sf() {}
  Da(t) {}
  static ff(t, e) {
    if (t && e)
      if (((t = t.toLowerCase()), j(e.nf))) {
        for (let r = 0; r < e.nf.length; r++)
          if (-1 !== t.indexOf(e.nf[r].toLowerCase())) return e.identity;
      } else if (-1 !== t.indexOf(e.nf.toLowerCase())) return e.identity;
  }
}
