import X from "../../util/browser-detector.js";
import { InAppMessage } from "../index.js";
import { OperatingSystems as Y } from "../../util/device-constants.js";
import {
  applyNonceToDynamicallyCreatedTags as lt,
  buildBrazeBridge as ct,
  buildHtmlClickHandler as at,
} from "./html-message-display-utils.js";
export default function dt(t, e, n, o, s, l) {
  const c = document.createElement("iframe");
  c.setAttribute("title", "Modal Message"),
    s && (c.style.zIndex = (s + 1).toString());
  let r = null;
  if (null != l) {
    (r = document.createElement("html")), (r.innerHTML = t.message || "");
    const e = r.getElementsByTagName("style");
    for (let t = 0; t < e.length; t++) e[t].setAttribute("nonce", l);
    const n = r.getElementsByTagName("script");
    for (let t = 0; t < n.length; t++) {
      n[t].setAttribute("nonce", l),
        (n[t].innerHTML = n[t].innerHTML.replace(
          /<style>/g,
          `<style nonce='${l}'>`,
        ));
      const e = lt(n[t].innerHTML, l, "script");
      e && (n[t].innerHTML = e);
      const o = lt(n[t].innerHTML, l, "style");
      o && (n[t].innerHTML = o);
    }
  }
  if (
    ((c.srcdoc = r ? r.innerHTML : t.message || ""),
    (c.onload = () => {
      const s = c.contentWindow;
      s.focus();
      const r = s.document.getElementsByTagName("head")[0];
      if (null != r) {
        if (t.ve()) {
          const e = document.createElement("style");
          (e.innerHTML = t.css || ""),
            (e.id = t.we() || ""),
            null != l && e.setAttribute("nonce", l),
            r.appendChild(e);
        }
        const e = s.document.createElement("base");
        null != e && (e.setAttribute("target", "_parent"), r.appendChild(e));
      }
      const i = s.document.getElementsByTagName("title");
      i && i.length > 0 && c.setAttribute("title", i[0].textContent || "");
      const a = ct(t, c, e);
      if (
        ((s.appboyBridge = a), (s.brazeBridge = a), t.xe !== InAppMessage.Be.Me)
      ) {
        const e = s.document.getElementsByTagName("a");
        for (let n = 0; n < e.length; n++) e[n].onclick = at(t, c, e[n], o);
        const n = s.document.getElementsByTagName("button");
        for (let e = 0; e < n.length; e++) n[e].onclick = at(t, c, n[e], o);
      }
      const d = s.document.body;
      if (null != d) {
        t.Ce() && (d.id = t.htmlId || "");
        const e = document.createElement("hidden");
        (e.onclick = a.closeMessage),
          (e.className = "ab-programmatic-close-button"),
          d.appendChild(e);
      }
      s.dispatchEvent(new CustomEvent("ab.BridgeReady")),
        -1 !== c.className.indexOf("ab-start-hidden") &&
          ((c.className = c.className.replace("ab-start-hidden", "")), n(c));
    }),
    (c.className =
      "ab-in-app-message ab-start-hidden ab-html-message ab-modal-interactions"),
    X.OS === Y.io)
  ) {
    const e = document.createElement("div");
    return (
      (e.className = "ab-ios-scroll-wrapper"), e.appendChild(c), (t.Re = e), e
    );
  }
  return (t.Re = c), c;
}
