import { isDate as Nt } from "../../util/code-utils.js";
import {
  convertMsToSeconds as h,
  dateFromUnixTimestamp as l,
  secondsAgo as es,
  secondsInTheFuture as ls,
} from "../../util/date-utils.js";
export default class lr {
  constructor(t, s, e, i) {
    (this.Tl = t),
      (this._l = s),
      (this.comparator = e),
      (this.Ll = i),
      (this.Tl = t),
      (this._l = s),
      (this.comparator = e),
      (this.Ll = i),
      this._l === lr.Ol.Il &&
        this.comparator !== lr.Ql.Ul &&
        this.comparator !== lr.Ql.Xl &&
        this.comparator !== lr.Ql.Fl &&
        this.comparator !== lr.Ql.Kl &&
        (this.Ll = l(this.Ll));
  }
  Jl(t) {
    let s = null;
    switch ((null != t && (s = t[this.Tl]), this.comparator)) {
      case lr.Ql.Pl:
        return null != s && s.valueOf() === this.Ll.valueOf();
      case lr.Ql.Yl:
        return null == s || s.valueOf() !== this.Ll.valueOf();
      case lr.Ql.Zl:
        return null != s && typeof s == typeof this.Ll && s > this.Ll;
      case lr.Ql.Ul:
        return this._l === lr.Ol.Il
          ? null != s && Nt(s) && es(s) <= this.Ll.valueOf()
          : null != s && typeof s == typeof this.Ll && s >= this.Ll;
      case lr.Ql.$l:
        return null != s && typeof s == typeof this.Ll && s < this.Ll;
      case lr.Ql.Xl:
        return this._l === lr.Ol.Il
          ? null != s && Nt(s) && es(s) >= this.Ll.valueOf()
          : null != s && typeof s == typeof this.Ll && s <= this.Ll;
      case lr.Ql.Hu:
        return (
          null != s &&
          "string" == typeof s &&
          typeof s == typeof this.Ll &&
          null != s.match(this.Ll)
        );
      case lr.Ql.Qu:
        return null != s;
      case lr.Ql.Xu:
        return null == s;
      case lr.Ql.Fl:
        return null != s && Nt(s) && ls(s) < this.Ll;
      case lr.Ql.Kl:
        return null != s && Nt(s) && ls(s) > this.Ll;
      case lr.Ql.Vu:
        return (
          null == s ||
          typeof s != typeof this.Ll ||
          "string" != typeof s ||
          null == s.match(this.Ll)
        );
    }
    return !1;
  }
  static fromJson(t) {
    return new lr(
      t.property_key,
      t.property_type,
      t.comparator,
      t.property_value,
    );
  }
  Y() {
    let t = this.Ll;
    return (
      Nt(this.Ll) && (t = h(t.valueOf())),
      { k: this.Tl, t: this._l, c: this.comparator, v: t }
    );
  }
  static qn(t) {
    return new lr(t.k, t.t, t.c, t.v);
  }
}
(lr.Ol = { Yu: "boolean", Zu: "number", yE: "string", Il: "date" }),
  (lr.Ql = {
    Pl: 1,
    Yl: 2,
    Zl: 3,
    Ul: 4,
    $l: 5,
    Xl: 6,
    Hu: 10,
    Qu: 11,
    Xu: 12,
    Fl: 15,
    Kl: 16,
    Vu: 17,
  });
