import { logger as r } from "../../shared-lib/index.js";
import FeatureFlag from "./feature-flag.js";
export function newFeatureFlagFromJson(e) {
  if (e[FeatureFlag.Rt.ht] && "boolean" == typeof e[FeatureFlag.Rt.le])
    return new FeatureFlag(
      e[FeatureFlag.Rt.ht],
      e[FeatureFlag.Rt.le],
      e[FeatureFlag.Rt.pe],
      e[FeatureFlag.Rt.Fe],
    );
  r.info(`Unable to create feature flag from ${JSON.stringify(e, null, 2)}`);
}
export function newFeatureFlagFromSerializedValue(e) {
  if (e[FeatureFlag.tt.ht] && "boolean" == typeof e[FeatureFlag.tt.le])
    return new FeatureFlag(
      e[FeatureFlag.tt.ht],
      e[FeatureFlag.tt.le],
      e[FeatureFlag.tt.pe],
      e[FeatureFlag.tt.Fe],
    );
  r.info(
    `Unable to deserialize feature flag from ${JSON.stringify(e, null, 2)}`,
  );
}
