import aa from "./in-app-message-manager.js";
import e from "../managers/braze-instance.js";
const se = {
  i: null,
  t: !1,
  m: () => (
    se.o(), se.i || (se.i = new aa(e.nr(), e.zr(), e.l(), e.er())), se.i
  ),
  o: () => {
    se.t || (e.g(se), (se.t = !0));
  },
  destroy: () => {
    (se.i = null), (se.t = !1);
  },
};
export default se;
