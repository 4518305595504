import is from "./filter-set.js";
export default class hs {
  constructor(t, s) {
    (this.productId = t), (this.Ml = s), (this.productId = t), (this.Ml = s);
  }
  Jl(t) {
    if (null == this.productId || null == this.Ml) return !1;
    const s = t[0],
      i = t[1];
    return s === this.productId && this.Ml.Jl(i);
  }
  static fromJson(t) {
    return new hs(
      t ? t.product_id : null,
      t ? is.fromJson(t.property_filters) : null,
    );
  }
  Y() {
    return { id: this.productId, pf: this.Ml ? this.Ml.Y() : null };
  }
}
