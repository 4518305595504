import { STORAGE_KEYS as o } from "./storage-manager.js";
import T from "./subscription-manager.js";
import Jt from "../models/server-config.js";
import s from "../common/event-logger.js";
import { EventTypes as i } from "../../shared-lib/index.js";
export default class Dt {
  constructor(t) {
    (this.u = t),
      (this.u = t),
      (this.tl = new T()),
      (this.el = new T()),
      (this.il = new T()),
      (this.sl = null),
      (this.rl = null);
  }
  ll() {
    if (null == this.rl) {
      const t = this.u.j(o.C.ul);
      this.rl = null != t ? Jt.qn(t) : new Jt();
    }
    return this.rl;
  }
  ii() {
    return this.ll().hl;
  }
  al(t) {
    if (null != t && null != t.config) {
      const e = t.config;
      if (e.time > this.ll().hl) {
        const t = (t) => (null == t ? this.ll().ol : t),
          i = new Jt(
            e.time,
            e.events_blacklist,
            e.attributes_blacklist,
            e.purchases_blacklist,
            e.messaging_session_timeout,
            e.vapid_public_key,
            e.content_cards,
            e.feature_flags,
            t(e.global_request_rate_limit),
          );
        let s = !1;
        null != i.cl && this.Wn() !== i.cl && (s = !0);
        let r = !1;
        null != i.ml.enabled && this.si() !== i.ml.enabled && (r = !0);
        let n = !1;
        null != i.hi.enabled && this.ci() !== i.hi.enabled && (n = !0),
          (this.rl = i),
          this.u.k(o.C.ul, i.Y()),
          s && this.tl.Dt(),
          r && this.el.Dt(),
          n && this.il.Dt();
      }
    }
  }
  _n(t) {
    const e = this.tl.It(t);
    return this.sl && this.tl.removeSubscription(this.sl), (this.sl = e), e;
  }
  Is(t) {
    return this.el.It(t);
  }
  ji(t) {
    return this.il.It(t);
  }
  me(t) {
    return -1 !== this.ll().gl.indexOf(t);
  }
  hu(t) {
    return -1 !== this.ll().fl.indexOf(t);
  }
  Dr(t) {
    return -1 !== this.ll().bl.indexOf(t);
  }
  dl() {
    return this.ll().Rl;
  }
  Wn() {
    return this.ll().cl;
  }
  si() {
    return this.ll().ml.enabled || !1;
  }
  Cl() {
    const t = this.ll().ol;
    return !(!t || null == t.enabled) && t.enabled;
  }
  Tu() {
    if (!this.Cl()) return -1;
    const t = this.ll().ol;
    return null == t.capacity || t.capacity < 10 ? -1 : t.capacity;
  }
  ku() {
    if (!this.Cl()) return -1;
    const t = this.ll().ol;
    return null == t.refill_rate || t.refill_rate <= 0 ? -1 : t.refill_rate;
  }
  vl(t) {
    const e = this.ll().ol.endpoint_overrides;
    return null == e ? null : e[t];
  }
  Cu(t) {
    const e = this.vl(t);
    return null == e || isNaN(e.capacity) || e.capacity <= 0 ? -1 : e.capacity;
  }
  Su(t) {
    const e = this.vl(t);
    return null == e || isNaN(e.refill_rate) || e.refill_rate <= 0
      ? -1
      : e.refill_rate;
  }
  ci() {
    return this.ll().hi.enabled && null == this.Ri()
      ? (s.q(i.qs, { e: "Missing feature flag refresh_rate_limit." }), !1)
      : this.ll().hi.enabled || !1;
  }
  Ri() {
    return this.ll().hi.refresh_rate_limit;
  }
}
