import {
  Card,
  CaptionedImage,
  ClassicCard,
  ControlCard,
  ImageOnly,
} from "../index.js";
import {
  dateFromUnixTimestamp as l,
  rehydrateDateAfterJsonization as w,
} from "../../util/date-utils.js";
import { logger as r } from "../../../shared-lib/index.js";
export function newCard(n, e, t, o, i, l, u, d, a, w, f, s, m, C, p, c, x, F) {
  let j;
  if (e === Card.it.Ot || e === Card.it.Mt)
    j = new ClassicCard(n, t, o, i, l, u, d, a, w, f, s, m, C, p, c, x);
  else if (e === Card.it.st)
    j = new CaptionedImage(n, t, o, i, l, u, d, a, w, f, s, m, C, p, c, x);
  else if (e === Card.it.Pt)
    j = new ImageOnly(n, t, i, u, d, a, w, f, s, m, C, p, c, x);
  else {
    if (e !== Card.it.Ut)
      return r.error("Ignoring card with unknown type " + e), null;
    j = new ControlCard(n, t, d, w, C, p);
  }
  return F && (j.test = F), j;
}
export function newCardFromContentCardsJson(n) {
  if (n[Card.Rt.Lt]) return null;
  const e = n[Card.Rt.ht],
    r = n[Card.Rt.Z],
    t = n[Card.Rt.et],
    o = n[Card.Rt.rt],
    i = n[Card.Rt.ot],
    u = n[Card.Rt.ct],
    d = l(n[Card.Rt.nt]),
    a = d;
  let w;
  w = n[Card.Rt.lt] === Card._t ? null : l(n[Card.Rt.lt]);
  return newCard(
    e,
    r,
    t,
    o,
    i,
    u,
    a,
    d,
    null,
    w,
    n[Card.Rt.URL],
    n[Card.Rt.ft],
    n[Card.Rt.xt],
    n[Card.Rt.bt],
    n[Card.Rt.gt],
    n[Card.Rt.jt],
    n[Card.Rt.zt],
    n[Card.Rt.kt] || !1,
  );
}
export function newCardFromFeedJson(n) {
  return newCard(
    n.id,
    n.type,
    n.viewed,
    n.title,
    n.image,
    n.description,
    l(n.created),
    l(n.updated),
    n.categories,
    l(n.expires_at),
    n.url,
    n.domain,
    n.aspect_ratio,
    n.extras,
    !1,
    !1,
  );
}
export function newCardFromSerializedValue(n) {
  return (
    newCard(
      n[Card.tt.ht],
      n[Card.tt.Z],
      n[Card.tt.et],
      n[Card.tt.rt],
      n[Card.tt.ot],
      n[Card.tt.ct],
      w(n[Card.tt.dt]),
      w(n[Card.tt.nt]),
      n[Card.tt.ut],
      w(n[Card.tt.lt]),
      n[Card.tt.URL],
      n[Card.tt.ft],
      n[Card.tt.xt],
      n[Card.tt.bt],
      n[Card.tt.gt],
      n[Card.tt.jt],
      n[Card.tt.zt],
      n[Card.tt.kt] || !1,
    ) || void 0
  );
}
