import e from "../managers/braze-instance.js";
import { logger as r, IndexedDBAdapter as A } from "../../shared-lib/index.js";
import Q, { STORAGE_KEYS as o } from "../managers/storage-manager.js";
export function enableSDK() {
  new Q.ee(null, !0).remove(o.se);
  const a = A.Yt.Qt;
  new A(a, r).re(a.ss.ae, a.ie),
    r.info("enableSDK was called"),
    e.destroy(!1),
    e.oe(!1);
}
