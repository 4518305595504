import { logger as r } from "../../shared-lib/index.js";
import Xt from "../models/backend-errors.js";
import { getErrorMessage as si } from "./error-utils.js";
const C = {
  Gs: (t) => {
    let e, o;
    try {
      const n = () => {
        r.error("This browser does not have any supported ajax options!");
      };
      let s = !1;
      if ((window.XMLHttpRequest && (s = !0), !s)) return void n();
      e = new XMLHttpRequest();
      const i = () => {
        "function" == typeof t.error && t.error(e.status),
          "function" == typeof t.Ws && t.Ws(!1);
      };
      (e.onload = () => {
        let o = !1;
        if (4 === e.readyState)
          if (
            ((o = (e.status >= 200 && e.status < 300) || 304 === e.status), o)
          ) {
            if ("function" == typeof t.L) {
              let o, r;
              try {
                (o = JSON.parse(e.responseText)),
                  (r = e.getAllResponseHeaders());
              } catch (o) {
                const n = {
                  error: "" === e.responseText ? Xt.Ra : Xt.qa,
                  response: e.responseText,
                };
                (0, t.L)(n, r);
              }
              o && t.L(o, r);
            }
            "function" == typeof t.Ws && t.Ws(!0);
          } else i();
      }),
        (e.onerror = () => {
          i();
        }),
        (e.ontimeout = () => {
          i();
        }),
        (o = JSON.stringify(t.data)),
        e.open("POST", t.url, !0),
        e.setRequestHeader("Content-type", "application/json"),
        e.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      const f = t.headers || [];
      for (const t of f) e.setRequestHeader(t[0], t[1]);
      e.send(o);
    } catch (t) {
      r.error(`Network request error: ${si(t)}`);
    }
  },
};
export const readResponseHeaders = (t) => {
  const e = {},
    o = t.toString().split("\r\n");
  if (!o) return e;
  let r, n;
  for (const t of o)
    t &&
      ((r = t.slice(0, t.indexOf(":")).toLowerCase().trim()),
      (n = t.slice(t.indexOf(":") + 1).trim()),
      (e[r] = n));
  return e;
};
export default C;
