import { logger as r } from "../../shared-lib/index.js";
import { ControlCard } from "../Card/index.js";
export const FEED_ANIMATION_DURATION = 500;
export default class x {
  constructor(s, e) {
    (this.cards = s),
      (this.lastUpdated = e),
      (this.cards = s),
      (this.lastUpdated = e);
  }
  getUnreadCardCount() {
    let s = 0;
    for (const e of this.cards) e.viewed || e instanceof ControlCard || s++;
    return s;
  }
  dr() {
    r.error("Must be implemented in a subclass");
  }
  logCardImpressions(s) {
    r.error("Must be implemented in a subclass");
  }
  logCardClick(s) {
    r.error("Must be implemented in a subclass");
  }
  sr() {
    r.error("Must be implemented in a subclass");
  }
}
(x.ur = 6e4), (x.Ah = 500), (x.Co = 1e4);
