import {
  GLOBAL_RATE_LIMIT_CAPACITY_DEFAULT as Zt,
  GLOBAL_RATE_LIMIT_REFILL_RATE_DEFAULT as hi,
} from "../common/constants.js";
export default class Jt {
  constructor(
    t = 0,
    i = [],
    s = [],
    h = [],
    e = null,
    r = null,
    l = { enabled: !1 },
    a = { enabled: !1, refresh_rate_limit: void 0 },
    n = { enabled: !0, capacity: Zt, refill_rate: hi, endpoint_overrides: {} },
  ) {
    (this.hl = t),
      (this.gl = i),
      (this.fl = s),
      (this.bl = h),
      (this.Rl = e),
      (this.cl = r),
      (this.ml = l),
      (this.hi = a),
      (this.ol = n),
      (this.hl = t),
      (this.gl = i),
      (this.fl = s),
      (this.bl = h),
      (this.Rl = e),
      (this.cl = r),
      (this.ml = l),
      (this.hi = a),
      (this.ol = n);
  }
  Y() {
    return {
      s: "5.4.0",
      l: this.hl,
      e: this.gl,
      a: this.fl,
      p: this.bl,
      m: this.Rl,
      v: this.cl,
      c: this.ml,
      f: this.hi,
      grl: this.ol,
    };
  }
  static qn(t) {
    let i = t.l;
    return (
      "5.4.0" !== t.s && (i = 0),
      new Jt(i, t.e, t.a, t.p, t.m, t.v, t.c, t.f, t.grl)
    );
  }
}
