export function isTransparent(r) {
  return (
    null != r &&
    ((r = parseInt(r.toString())), !isNaN(r) && (4278190080 & r) >>> 24 == 0)
  );
}
export function toRgba(r, n) {
  if (null == r) return "";
  if (((r = parseInt(r.toString())), isNaN(r))) return "";
  (n && !isNaN(parseFloat(n.toString()))) || (n = 1);
  return (
    "rgba(" +
    [
      (16711680 & (r >>>= 0)) >>> 16,
      (65280 & r) >>> 8,
      255 & r,
      (((4278190080 & r) >>> 24) / 255) * n,
    ].join(",") +
    ")"
  );
}
export function toRgb(r) {
  if (null == r || isNaN(parseInt(r.toString()))) return "";
  return (
    "rgb(" +
    [(16711680 & (r >>>= 0)) >>> 16, (65280 & r) >>> 8, 255 & r].join(",") +
    ")"
  );
}
