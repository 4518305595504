import e from "../managers/braze-instance.js";
import HtmlMessage from "./models/html-message.js";
import se from "./in-app-message-manager-factory.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import tt from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as et } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageHtmlClick(t, s, o) {
  if (!e.X()) return !1;
  if (!(t instanceof HtmlMessage))
    return (
      r.error(
        "inAppMessage argument to logInAppMessageHtmlClick must be an HtmlMessage object.",
      ),
      !1
    );
  let m = i.$i;
  null != s && (m = i.Ei);
  const n = se.m().q(t, m, s, o);
  if (n.L)
    for (let e = 0; e < n.ge.length; e++)
      et.rr().fe(tt.Qr, [t.triggerId, s], n.ge[e]);
  return n.L;
}
