import { logger as r } from "../../shared-lib/index.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as _ } from "../common/constants.js";
import e from "../managers/braze-instance.js";
import {
  BRAZE_ACTIONS as V,
  getDecodedBrazeAction as W,
} from "../util/braze-actions.js";
import X from "../util/browser-detector.js";
import { OperatingSystems as Y } from "../util/device-constants.js";
import {
  BRAZE_ACTION_URI_REGEX as Z,
  isValidBrazeActionJson as oo,
} from "../util/validation-utils.js";
import { WindowUtils as eo } from "../util/window-utils.js";
import { getUser as ro } from "./get-user.js";
export function _handleBrazeAction(o, s, t) {
  if (e.X())
    if (Z.test(o)) {
      const s = W(o);
      if (!s) return;
      const t = (o) => {
        if (!oo(o))
          return void r.error(
            `Decoded Braze Action json is invalid: ${JSON.stringify(
              o,
              null,
              2,
            )}`,
          );
        const s = V.properties.type,
          i = V.properties.oo,
          n = V.properties.eo,
          a = o[s];
        if (a === V.types.ro) {
          const e = o[i];
          for (const o of e) t(o);
        } else {
          const s = o[n];
          let t, i;
          switch (a) {
            case V.types.logCustomEvent:
              import("./log-custom-event.js").then(
                ({ logCustomEvent: logCustomEvent }) => {
                  e.so()
                    ? ((i = Array.prototype.slice.call(s)),
                      logCustomEvent(...i))
                    : r.error(_);
                },
              );
              break;
            case V.types.requestPushPermission:
              import("../Push/request-push-permission.js").then(
                ({ requestPushPermission: requestPushPermission }) => {
                  e.so()
                    ? "Safari" === X.browser && X.OS === Y.io
                      ? window.navigator.standalone && requestPushPermission()
                      : requestPushPermission()
                    : r.error(_);
                },
              );
              break;
            case V.types.setEmailNotificationSubscriptionType:
            case V.types.setPushNotificationSubscriptionType:
            case V.types.setCustomUserAttribute:
            case V.types.addToSubscriptionGroup:
            case V.types.removeFromSubscriptionGroup:
            case V.types.addToCustomAttributeArray:
            case V.types.removeFromCustomAttributeArray:
              if (((t = ro()), t)) {
                t[a](...Array.prototype.slice.call(s));
              }
              break;
            case V.types.no:
            case V.types.ao:
              (i = Array.prototype.slice.call(s)), eo.openUri(...i);
              break;
            default:
              r.info(`Ignoring unknown Braze Action: ${a}`);
          }
        }
      };
      t(s);
    } else eo.openUri(o, s, t);
}
export function handleBrazeAction(o, e) {
  _handleBrazeAction(o, e);
}
