import e from "../managers/braze-instance.js";
import { logger as r, IndexedDBAdapter as A } from "../../shared-lib/index.js";
import Q, { STORAGE_KEYS as o } from "../managers/storage-manager.js";
export function disableSDK() {
  const n = e.cr();
  n && n.requestImmediateDataFlush();
  const s = new Q.ee(null, !0),
    a = "This-cookie-will-expire-in-" + s.ne();
  s.store(o.se, a);
  const i = A.Yt.Qt;
  new A(i, r).setItem(i.ss.ae, i.ie, !0),
    r.info("disableSDK was called"),
    e.destroy(!1),
    e.oe(!0);
}
