import e from "../managers/braze-instance.js";
import se from "./in-app-message-manager-factory.js";
export function subscribeToInAppMessage(n) {
  if (e.X())
    return "function" != typeof n
      ? null
      : se.m().Mi(function (r) {
          return n(r[0]), r.slice(1);
        });
}
