import { logger as r } from "../../shared-lib/index.js";
import xt from "../common/translations.js";
export default class nr {
  constructor(t, e = !1) {
    if (
      ((this.language = t),
      null != t && (t = t.toLowerCase()),
      null != t && null == xt[t])
    ) {
      const e = t.indexOf("-");
      e > 0 && (t = t.substring(0, e));
    }
    if (null == xt[t]) {
      const a =
        "Braze does not yet have a localization for language " +
        t +
        ", defaulting to English. Please contact us if you are willing and able to help us translate our SDK into this language.";
      e ? r.error(a) : r.info(a), (t = "en");
    }
    this.language = t;
  }
  get(t) {
    return xt[this.language][t];
  }
  wo() {
    switch (this.language) {
      case "ar":
      case "he":
      case "fa":
        return "rtl";
      default:
        return "ltr";
    }
  }
}
