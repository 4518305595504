import { logger as r } from "../../shared-lib/index.js";
import y from "../common/base-provider.js";
import e from "../managers/braze-instance.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import T from "../managers/subscription-manager.js";
import { randomInclusive as U } from "../util/math.js";
import C from "../util/net.js";
import {
  newFeatureFlagFromJson as st,
  newFeatureFlagFromSerializedValue as it,
} from "./feature-flag-factory.js";
import D from "../util/request-header-utils.js";
export default class er extends y {
  constructor(t, s, i, r) {
    super(),
      (this.yt = t),
      (this.qt = s),
      (this.u = i),
      (this.ri = r),
      (this.hi = []),
      (this.oi = 0),
      (this.yt = t),
      (this.qt = s),
      (this.u = i),
      (this.ri = r),
      (this.ai = null),
      (this.ni = new T()),
      (this.li = 10),
      (this.ui = null),
      (this.fi = null),
      e.$t(this.ni);
  }
  Ns(t) {
    var s;
    if (
      (null === (s = this.yt) || void 0 === s ? void 0 : s.ci()) &&
      null != t &&
      t.feature_flags
    ) {
      this.hi = [];
      for (const s of t.feature_flags) {
        const t = st(s);
        t && this.hi.push(t);
      }
      (this.oi = new Date().getTime()), this.di(), this.ni.Dt(this.hi);
    }
  }
  mi() {
    let t = {};
    this.u && (t = this.u.j(o.C.gi));
    const s = {};
    for (const i in t) {
      const e = it(t[i]);
      e && (s[e.id] = e);
    }
    return s;
  }
  Fi() {
    var t;
    return (null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.vi)) || {};
  }
  pi(t) {
    this.u && this.u.k(o.C.vi, t);
  }
  Zs(t) {
    return this.ni.It(t);
  }
  refreshFeatureFlags(t, s, i = !1, e = !0) {
    const r = () => {
      "function" == typeof s && s(), this.ni.Dt(this.hi);
    };
    if (!this.wi(i))
      return (
        !this.ai &&
          this.yt &&
          (this.ai = this.yt.ji(() => {
            this.refreshFeatureFlags(t, s);
          })),
        void r()
      );
    const h = this.qt;
    if (!h) return void r();
    e && this.yi();
    const o = h.Js({}, !0),
      a = h.$s(o, D.Ps.bi);
    let n = !1;
    h.Xs(
      o,
      (h = -1) => {
        if (!this.qt) return void r();
        const l = new Date().valueOf();
        D._s(this.u, D.Ps.bi, l),
          -1 !== h && a.push(["X-Braze-Req-Tokens-Remaining", h.toString()]),
          C.Gs({
            url: `${this.qt.Hs()}/feature_flags/sync`,
            headers: a,
            data: o,
            L: (s) => {
              if (!this.qt.Ks(o, s, a)) return (n = !0), void r();
              this.qt.Os(),
                this.Ns(s),
                (n = !1),
                D.Qs(this.u, D.Ps.bi, 1),
                "function" == typeof t && t();
            },
            error: (t) => {
              this.qt.Vs(t, "retrieving feature flags"), (n = !0), r();
            },
            Ws: () => {
              if (e && n && !this.fi) {
                D.Ys(this.u, D.Ps.bi);
                let e = this.ui;
                (null == e || e < 1e3 * this.li) && (e = 1e3 * this.li),
                  this.Ci(Math.min(3e5, U(1e3 * this.li, 3 * e)), t, s, i);
              }
            },
          });
      },
      D.Ps.bi,
      s,
    );
  }
  yi() {
    null != this.fi && (clearTimeout(this.fi), (this.fi = null));
  }
  Ci(t = 1e3 * this.li, s, i, e = !1) {
    this.yi(),
      (this.fi = window.setTimeout(() => {
        this.refreshFeatureFlags(s, i, e);
      }, t)),
      (this.ui = t);
  }
  wi(t) {
    if (!this.yt) return !1;
    if (!t) {
      const t = this.yt.Ri();
      if (null == t) return !1;
      let s = !1;
      if (!isNaN(t)) {
        if (-1 === t) return r.info("Feature flag refreshes not allowed"), !1;
        s = new Date().getTime() >= (this.oi || 0) + 1e3 * t;
      }
      if (!s)
        return (
          r.info(`Feature flag refreshes were rate limited to ${t} seconds`), !1
        );
    }
    return this.yt.ci();
  }
  Ti() {
    var t;
    return (
      (null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.Di)) || null
    );
  }
  Ii() {
    var t, s;
    null === (t = this.u) ||
      void 0 === t ||
      t.k(o.C.Di, null === (s = this.ri) || void 0 === s ? void 0 : s.Si());
  }
  qi() {
    var t;
    const s = null === (t = this.ri) || void 0 === t ? void 0 : t.Si(),
      i = this.Ti();
    return null == i || s === i;
  }
  di() {
    if (!this.u) return;
    const t = {};
    for (const s of this.hi) {
      const i = s.Y();
      t[s.id] = i;
    }
    this.u.k(o.C.gi, t), this.u.k(o.C.xi, this.oi), this.Ii();
  }
}
