import {
  convertMsToSeconds as h,
  dateFromUnixTimestamp as l,
} from "../../util/date-utils.js";
import { FEED_ANIMATION_DURATION as E } from "../../common/constants.js";
import T from "../../managers/subscription-manager.js";
import r from "../../../shared-lib/logger.js";
export default class Card {
  constructor(t, i, s, h, l, n, e, r, u, E, o, T, I, a, N, c) {
    (this.id = t),
      (this.viewed = i),
      (this.title = s),
      (this.imageUrl = h),
      (this.description = l),
      (this.created = n),
      (this.updated = e),
      (this.categories = r),
      (this.expiresAt = u),
      (this.url = E),
      (this.linkText = o),
      (this.aspectRatio = T),
      (this.extras = I),
      (this.pinned = a),
      (this.dismissible = N),
      (this.clicked = c),
      (this.id = t),
      (this.viewed = i || !1),
      (this.title = s || ""),
      (this.imageUrl = h),
      (this.description = l || ""),
      (this.created = n || null),
      (this.updated = e || null),
      (this.categories = r || []),
      (this.expiresAt = u || null),
      (this.url = E),
      (this.linkText = o),
      null == T
        ? (this.aspectRatio = null)
        : ((T = parseFloat(T.toString())),
          (this.aspectRatio = isNaN(T) ? null : T)),
      (this.extras = I || {}),
      (this.pinned = a || !1),
      (this.dismissible = N || !1),
      (this.dismissed = !1),
      (this.clicked = c || !1),
      (this.isControl = !1),
      (this.test = !1),
      (this.Et = null),
      (this.Tt = null);
  }
  subscribeToClickedEvent(t) {
    return this.Nt().It(t);
  }
  subscribeToDismissedEvent(t) {
    return this.St().It(t);
  }
  removeSubscription(t) {
    this.Nt().removeSubscription(t), this.St().removeSubscription(t);
  }
  removeAllSubscriptions() {
    this.Nt().removeAllSubscriptions(), this.St().removeAllSubscriptions();
  }
  dismissCard() {
    if (!this.dismissible || this.dismissed) return;
    "function" == typeof this.logCardDismissal && this.logCardDismissal();
    let t = this.T;
    !t && this.id && (t = document.getElementById(this.id)),
      t &&
        ((t.style.height = t.offsetHeight + "px"),
        (t.className = t.className + " ab-hide"),
        setTimeout(function () {
          t &&
            t.parentNode &&
            ((t.style.height = "0"),
            (t.style.margin = "0"),
            setTimeout(function () {
              t && t.parentNode && t.parentNode.removeChild(t);
            }, Card.At));
        }, E));
  }
  Nt() {
    return null == this.Et && (this.Et = new T()), this.Et;
  }
  St() {
    return null == this.Tt && (this.Tt = new T()), this.Tt;
  }
  K() {
    this.viewed = !0;
  }
  p() {
    (this.viewed = !0), (this.clicked = !0), this.Nt().Dt();
  }
  N() {
    return (
      !(!this.dismissible || this.dismissed) &&
      ((this.dismissed = !0), this.St().Dt(), !0)
    );
  }
  Ct(t) {
    if (null == t || t[Card.Rt.ht] !== this.id) return !0;
    if (t[Card.Rt.Lt]) return !1;
    if (
      null != t[Card.Rt.nt] &&
      null != this.updated &&
      parseInt(t[Card.Rt.nt]) < h(this.updated.valueOf())
    )
      return !0;
    if (
      (t[Card.Rt.et] && !this.viewed && (this.viewed = !0),
      t[Card.Rt.zt] && !this.clicked && (this.clicked = t[Card.Rt.zt]),
      null != t[Card.Rt.rt] && (this.title = t[Card.Rt.rt]),
      null != t[Card.Rt.ot] && (this.imageUrl = t[Card.Rt.ot]),
      null != t[Card.Rt.ct] && (this.description = t[Card.Rt.ct]),
      null != t[Card.Rt.nt])
    ) {
      const i = l(t[Card.Rt.nt]);
      null != i && (this.updated = i);
    }
    if (null != t[Card.Rt.lt]) {
      let i;
      (i = t[Card.Rt.lt] === Card._t ? null : l(t[Card.Rt.lt])),
        (this.expiresAt = i);
    }
    if (
      (null != t[Card.Rt.URL] && (this.url = t[Card.Rt.URL]),
      null != t[Card.Rt.ft] && (this.linkText = t[Card.Rt.ft]),
      null != t[Card.Rt.xt])
    ) {
      const i = parseFloat(t[Card.Rt.xt].toString());
      this.aspectRatio = isNaN(i) ? null : i;
    }
    return (
      null != t[Card.Rt.bt] && (this.extras = t[Card.Rt.bt]),
      null != t[Card.Rt.gt] && (this.pinned = t[Card.Rt.gt]),
      null != t[Card.Rt.jt] && (this.dismissible = t[Card.Rt.jt]),
      null != t[Card.Rt.kt] && (this.test = t[Card.Rt.kt]),
      !0
    );
  }
  Y() {
    r.error("Must be implemented in a subclass");
  }
}
(Card._t = -1),
  (Card.Rt = {
    ht: "id",
    et: "v",
    jt: "db",
    Lt: "r",
    nt: "ca",
    gt: "p",
    lt: "ea",
    bt: "e",
    Z: "tp",
    ot: "i",
    rt: "tt",
    ct: "ds",
    URL: "u",
    ft: "dm",
    xt: "ar",
    zt: "cl",
    kt: "t",
  }),
  (Card.it = {
    st: "captioned_image",
    Ot: "text_announcement",
    Mt: "short_news",
    Pt: "banner_image",
    Ut: "control",
  }),
  (Card.tt = {
    ht: "id",
    et: "v",
    jt: "db",
    dt: "cr",
    nt: "ca",
    gt: "p",
    ut: "t",
    lt: "ea",
    bt: "e",
    Z: "tp",
    ot: "i",
    rt: "tt",
    ct: "ds",
    URL: "u",
    ft: "dm",
    xt: "ar",
    zt: "cl",
    kt: "s",
  }),
  (Card.Gt = {
    Xt: "ADVERTISING",
    vt: "ANNOUNCEMENTS",
    Vt: "NEWS",
    Wt: "SOCIAL",
  }),
  (Card.At = 400);
