import Xt from "../models/backend-errors.js";
import ve from "../models/braze-event.js";
import {
  convertMsToSeconds as h,
  convertSecondsToMs as Kt,
} from "../util/date-utils.js";
import s from "../common/event-logger.js";
import { isArray as j, isEqual as ii } from "../util/code-utils.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import { STORAGE_KEYS as o } from "./storage-manager.js";
import D from "../util/request-header-utils.js";
import { LAST_REQUEST_TO_ENDPOINT_MS_AGO_DEFAULT as Ht } from "../common/constants.js";
import { getAlias as Yt } from "./utils.js";
export default class Pt {
  constructor(t, e, i, s, r, n, o, h, a, u, l, c) {
    (this.on = t),
      (this.u = e),
      (this.Ko = i),
      (this.wt = s),
      (this.ri = r),
      (this.yt = n),
      (this.rn = o),
      (this.Go = h),
      (this.Bo = a),
      (this.Vo = u),
      (this.appVersion = l),
      (this.Xh = c),
      (this.Hh = (t) => (null == t ? "" : `${t} `)),
      (this.on = t),
      (this.u = e),
      (this.Ko = i),
      (this.wt = s),
      (this.ri = r),
      (this.yt = n),
      (this.rn = o),
      (this.Go = h),
      (this.Bo = a),
      (this.Vo = u),
      (this.appVersion = l),
      (this.Xh = c),
      (this.Yh = ["npm"]);
  }
  Js(t, e = !1, i = !1) {
    const s = this.on.ce(!i),
      r = s.Zi(),
      n = this.u.j(o.C.Fa);
    ii(n, r) || (t.device = r),
      (t.api_key = this.rn),
      (t.time = h(new Date().valueOf(), !0));
    const a = this.u.j(o.C.Jh) || [],
      u = this.u.j(o.C.Qh) || "";
    if (
      (this.Yh.length > 0 &&
        (!ii(a, this.Yh) || u !== this.ri.Si()) &&
        (t.sdk_metadata = this.Yh),
      (t.sdk_version = this.Bo),
      this.Vo && (t.sdk_flavor = this.Vo),
      (t.app_version = this.appVersion),
      (t.app_version_code = this.Xh),
      (t.device_id = s.id),
      e)
    ) {
      const e = this.wt.getUserId();
      null != e && (t.user_id = e);
    }
    if (!t.user_id && !this.Ko.Fh()) {
      const e = Yt(this.u);
      e && (t.alias = e);
    }
    return t;
  }
  Ks(t, e, n) {
    const o = e.auth_error,
      h = e.error;
    if (!o && !h) return !0;
    if (o) {
      let e;
      this.Ko.Bh();
      const i = { errorCode: o.error_code };
      for (const t of n)
        j(t) && "X-Braze-Auth-Signature" === t[0] && (i.signature = t[1]);
      t.respond_with && t.respond_with.user_id
        ? (i.userId = t.respond_with.user_id)
        : t.user_id && (i.userId = t.user_id);
      const s = o.reason;
      return (
        s
          ? ((i.reason = s), (e = `due to ${s}`))
          : (e = `with error code ${o.error_code}.`),
        this.Ko.Fh() ||
          (e +=
            ' Please use the "enableSdkAuthentication" initialization option to enable authentication.'),
        r.error(`SDK Authentication failed ${e}`),
        this.Zh(t.events || [], t.attributes || []),
        this.Ko.qh(i),
        !1
      );
    }
    if (h) {
      let n,
        o = h;
      switch (o) {
        case Xt.Ra:
          return (
            (n = "Received successful response with empty body."),
            s.q(i.qs, { e: n }),
            r.info(n),
            !1
          );
        case Xt.qa:
          return (
            (n = "Received successful response with invalid JSON"),
            s.q(i.qs, { e: n + ": " + e.response }),
            r.info(n),
            !1
          );
        case Xt.Aa:
          o = `The API key "${t.api_key}" is invalid for the baseUrl ${this.Go}`;
          break;
        case Xt.Ba:
          o =
            "Sorry, we are not currently accepting your requests. If you think this is in error, please contact us.";
          break;
        case Xt.Na:
          o = "No device identifier. Please contact support@braze.com";
      }
      r.error("Backend error: " + o);
    }
    return !1;
  }
  Xa(t, e, i, s) {
    return !!((t && 0 !== t.length) || (e && 0 !== e.length) || i || s);
  }
  $a(t, e, i, s, r = !1) {
    const n = [],
      o = (t) => t || "",
      h = o(this.wt.getUserId());
    let a = this.Yi(t, e);
    const u = [],
      l = [];
    let c,
      d = null;
    if (i.length > 0) {
      const t = [];
      for (const e of i) {
        if (((c = e.Zi()), this.Ko.Fh())) {
          if (h && !c.user_id) {
            d || (d = {}), d.events || (d.events = []), d.events.push(c);
            continue;
          }
          if (o(c.user_id) !== h) {
            l.push(c);
            continue;
          }
        }
        t.push(c);
      }
      t.length > 0 && (a.events = t);
    }
    if (s.length > 0) {
      const t = [];
      for (const e of s)
        e && (this.Ko.Fh() && o(e.user_id) !== h ? u.push(e) : t.push(e));
      t.length > 0 && (a.attributes = t);
    }
    if ((this.Zh(l, u), (a = this.Js(a, !0, r)), d)) {
      d = this.Js(d, !1, r);
      const t = { requestData: d, headers: this.$s(d, D.Ps.La) };
      n.push(t);
    }
    if (a && !this.Xa(a.events, a.attributes, t, e)) return d ? n : null;
    const f = { requestData: a, headers: this.$s(a, D.Ps.La) };
    return n.push(f), n;
  }
  Zh(t, e) {
    if (t) {
      const e = [];
      for (const i of t) {
        const t = ve.fromJson(i);
        (t.time = Kt(t.time)), e.push(t);
      }
      this.u.zo(e);
    }
    if (e) for (const t of e) this.u.Ea(t);
  }
  Vs(t, e) {
    let i = "HTTP error ";
    null != t && (i += t + " "), (i += e), r.error(i);
  }
  qr(t) {
    return s.q(i.Ka, { n: t });
  }
  Yi(t, e, i) {
    const s = {};
    t && (s.feed = !0), e && (s.triggers = !0);
    const r = null != i ? i : this.wt.getUserId();
    if ((r && (s.user_id = r), !s.user_id && !this.Ko.Fh())) {
      const t = Yt(this.u);
      t && (s.alias = t);
    }
    return (s.config = { config_time: this.yt.ii() }), { respond_with: s };
  }
  Pa(t) {
    const e = new Date().valueOf();
    let i = Ht.toString();
    const s = D.Ua(this.u, t);
    if (-1 !== s) {
      i = (e - s).toString();
    }
    return i;
  }
  $s(t, e, i = !1) {
    const s = [["X-Braze-Api-Key", this.rn]],
      r = this.Pa(e);
    s.push(["X-Braze-Last-Req-Ms-Ago", r]);
    const n = D.Ha(this.u, e).toString();
    s.push(["X-Braze-Req-Attempt", n]);
    let h = !1;
    if (
      (null != t.respond_with &&
        t.respond_with.triggers &&
        (s.push(["X-Braze-TriggersRequest", "true"]), (h = !0)),
      null != t.respond_with &&
        t.respond_with.feed &&
        (s.push(["X-Braze-FeedRequest", "true"]), (h = !0)),
      e === D.Ps.Bs)
    ) {
      s.push(["X-Braze-ContentCardsRequest", "true"]);
      let t = this.u.j(o.C.As);
      (t && i) || ((t = 0), this.u.k(o.C.As, t)),
        s.push(["BRAZE-SYNC-RETRY-COUNT", t.toString()]),
        (h = !0);
    }
    if (
      (e === D.Ps.bi &&
        (s.push(["X-Braze-FeatureFlagsRequest", "true"]), (h = !0)),
      h && s.push(["X-Braze-DataRequest", "true"]),
      this.Ko.Fh())
    ) {
      const t = this.Ko.kh();
      null != t && s.push(["X-Braze-Auth-Signature", t]);
    }
    return s;
  }
  Wa(t, e, i, s) {
    window.setTimeout(() => {
      r.info(`Retrying rate limited ${this.Hh(s)}SDK request.`),
        this.Xs(e, i, s);
    }, t);
  }
  Xs(t, e, i, s) {
    if (!this.Ya(i))
      return (
        r.info(`${this.Hh(i)}SDK request being rate limited.`),
        void ("function" == typeof s && s())
      );
    const n = this.Ja();
    if (!n.Qa)
      return (
        r.info(
          `${this.Hh(
            i,
          )}SDK request being rate limited. Request will be retried in ${Math.trunc(
            n.Va / 1e3,
          )} seconds.`,
        ),
        void this.Wa(n.Va, t, e, i)
      );
    this.u.k(o.C.Za, new Date().valueOf());
    const h = t.device;
    h && h.os_version instanceof Promise
      ? h.os_version.then((i) => {
          (t.device.os_version = i), e(n.au);
        })
      : e(n.au);
  }
  du(t) {
    var e;
    null === (e = this.u) || void 0 === e || e.k(o.C.fu, t);
  }
  vu(t, e) {
    let i = this.Ru();
    null == i && (i = {}), (i[t] = e), this.u.k(o.C.gu, i);
  }
  pu() {
    var t;
    return null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.fu);
  }
  Ru() {
    var t;
    return null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.gu);
  }
  bu(t, e, i, s, r = "") {
    let n;
    if (r) {
      const t = this.Ru();
      n = null == t || isNaN(t[r]) ? e : t[r];
    } else (n = this.pu()), (null == n || isNaN(n)) && (n = e);
    const o = (t - s) / 1e3;
    return (n = Math.min(n + o / i, e)), n;
  }
  qu(t, e) {
    return Math.max(0, (1 - t) * e * 1e3);
  }
  Au(t, e = "") {
    var i, s, r, n, h;
    const a = { Qa: !0, au: -1, Va: 0 };
    if ((null == t && (t = !0), !t && !e)) return a;
    let u,
      l,
      c = null;
    if (t) c = null === (i = this.u) || void 0 === i ? void 0 : i.j(o.C.Za);
    else {
      const t = D.Du(this.u);
      if (null == t || null == t[e]) return a;
      c = t[e];
    }
    if (null == c || isNaN(c)) return a;
    if (
      (t
        ? ((u =
            (null === (s = this.yt) || void 0 === s ? void 0 : s.Tu()) || -1),
          (l =
            (null === (r = this.yt) || void 0 === r ? void 0 : r.ku()) || -1))
        : ((u =
            (null === (n = this.yt) || void 0 === n ? void 0 : n.Cu(e)) || -1),
          (l =
            (null === (h = this.yt) || void 0 === h ? void 0 : h.Su(e)) || -1)),
      -1 === u || -1 === l)
    )
      return a;
    const d = new Date().valueOf();
    let f = this.bu(d, u, l, c, e);
    return f < 1
      ? ((a.Qa = !1), (a.Va = this.qu(f, l)), a)
      : ((f = Math.trunc(f) - 1),
        (a.au = f),
        t ? this.du(f) : this.vu(e, f),
        a);
  }
  Ja() {
    return this.Au(!0);
  }
  Ya(t) {
    const e = this.Au(!1, t);
    return !(e && !e.Qa);
  }
  Os() {
    this.Ko.Os();
  }
  Hs() {
    return this.Go;
  }
  addSdkMetadata(t) {
    for (const e of t) -1 === this.Yh.indexOf(e) && this.Yh.push(e);
  }
}
