import e from "../managers/braze-instance.js";
import er from "./feature-flags-provider.js";
const ir = {
  t: !1,
  provider: null,
  rr: () => (
    ir.o(),
    ir.provider ||
      ((ir.provider = new er(e.ir(), e.nr(), e.l(), e.zr())),
      e.ar(ir.provider)),
    ir.provider
  ),
  o: () => {
    ir.t || (e.g(ir), (ir.t = !0));
  },
  destroy: () => {
    (ir.provider = null), (ir.t = !1);
  },
};
export default ir;
