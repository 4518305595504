import { Guid as p } from "../../shared-lib/index.js";
export default class T {
  constructor() {
    this._e = {};
  }
  It(t) {
    if ("function" != typeof t) return null;
    const i = p.W();
    return (this._e[i] = t), i;
  }
  removeSubscription(t) {
    delete this._e[t];
  }
  removeAllSubscriptions() {
    this._e = {};
  }
  ic() {
    return Object.keys(this._e).length;
  }
  Dt(t) {
    const i = [];
    for (const s in this._e) {
      const r = this._e[s];
      i.push(r(t));
    }
    return i;
  }
}
