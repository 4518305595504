import e from "../managers/braze-instance.js";
import { keys as to } from "../util/code-utils.js";
import { logger as r, IndexedDBAdapter as A } from "../../shared-lib/index.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as _ } from "../common/constants.js";
export function wipeData() {
  const o = e.l();
  if (null == o) return void r.warn(_);
  o.clearData();
  const t = to(A.Yt);
  for (let o = 0; o < t.length; o++) {
    const n = t[o],
      i = A.Yt[n];
    new A(i, r).clearData();
  }
  if (e.X()) for (const o of e.gr()) o.clearData(!0);
}
