import {
  dateFromUnixTimestamp as l,
  rehydrateDateAfterJsonization as w,
} from "../../util/date-utils.js";
import { logger as r } from "../../../shared-lib/index.js";
import ri from "./trigger-condition.js";
import { validateValueIsFromEnum as J } from "../../util/code-utils.js";
export default class gt {
  constructor(t, i = [], s, e, r = 0, h, l, o = 0, n = gt.td, a, u, d) {
    (this.id = t),
      (this.sd = i),
      (this.startTime = s),
      (this.endTime = e),
      (this.priority = r),
      (this.type = h),
      (this.data = l),
      (this.ed = o),
      (this.rd = n),
      (this.Gr = a),
      (this.hd = u),
      (this.od = d),
      (this.id = t),
      (this.sd = i || []),
      void 0 === s && (s = null),
      (this.startTime = s),
      void 0 === e && (e = null),
      (this.endTime = e),
      (this.priority = r || 0),
      (this.type = h),
      (this.ed = o || 0),
      null == a && (a = 1e3 * (this.ed + 30)),
      (this.Gr = a),
      (this.data = l),
      null != n && (this.rd = n),
      (this.hd = u),
      (this.od = d || null);
  }
  nd(t) {
    return (
      null == this.od || (this.rd !== gt.td && t - this.od >= 1e3 * this.rd)
    );
  }
  ad(t) {
    this.od = t;
  }
  ud(t) {
    const i = t + 1e3 * this.ed;
    return Math.max(i - new Date().valueOf(), 0);
  }
  dd(t) {
    const i = new Date().valueOf() - t,
      s = null == t || isNaN(i) || null == this.Gr || i < this.Gr;
    return (
      s ||
        r.info(
          `Trigger action ${this.type} is no longer eligible for display - fired ${i}ms ago and has a timeout of ${this.Gr}ms.`,
        ),
      !s
    );
  }
  static fromJson(t) {
    const i = t.id,
      s = [];
    for (let i = 0; i < t.trigger_condition.length; i++)
      s.push(ri.fromJson(t.trigger_condition[i]));
    const e = l(t.start_time),
      r = l(t.end_time),
      h = t.priority,
      o = t.type,
      n = t.delay,
      a = t.re_eligibility,
      u = t.timeout,
      d = t.data,
      m = t.min_seconds_since_last_trigger;
    return J(
      gt.Br,
      o,
      "Could not construct Trigger from server data",
      "Trigger.Types",
    )
      ? new gt(i, s, e, r, h, o, d, n, a, u, m)
      : null;
  }
  Y() {
    const t = [];
    for (let i = 0; i < this.sd.length; i++) t.push(this.sd[i].Y());
    return {
      i: this.id,
      c: t,
      s: this.startTime,
      e: this.endTime,
      p: this.priority,
      t: this.type,
      da: this.data,
      d: this.ed,
      r: this.rd,
      tm: this.Gr,
      ss: this.hd,
      ld: this.od,
    };
  }
  static qn(t) {
    const i = [],
      s = t.c || [];
    for (let t = 0; t < s.length; t++) i.push(ri.qn(s[t]));
    return new gt(
      t.i,
      i,
      w(t.s),
      w(t.e),
      t.p,
      t.t,
      t.da,
      t.d,
      t.r,
      t.tm,
      t.ss,
      t.ld,
    );
  }
}
(gt.Br = { Tr: "inapp", md: "templated_iam" }), (gt.td = -1);
