import e, { OPTIONS as L } from "../../managers/braze-instance.js";
export function attachCSS(n, t, o) {
  const c = n || document.querySelector("head"),
    s = `ab-${t}-css-definitions-${"5.4.0".replace(/\./g, "-")}`;
  if (!c) return;
  const a = c.ownerDocument || document;
  if (null == a.getElementById(s)) {
    const n = a.createElement("style");
    (n.innerHTML = o || ""), (n.id = s);
    const t = e.nn(L.bo);
    null != t && n.setAttribute("nonce", t), c.appendChild(n);
  }
}
