import { STORAGE_KEYS as o } from "./storage-manager.js";
import T from "./subscription-manager.js";
import { logger as r, IndexedDBAdapter as A } from "../../shared-lib/index.js";
export default class qt {
  constructor(t, i, s) {
    (this.u = t),
      (this.Jn = i),
      (this.Qn = s),
      (this.u = t),
      (this.Jn = i || !1),
      (this.Qn = s),
      (this.Xn = new T()),
      (this.Zn = 0),
      (this.gh = 1);
  }
  Fh() {
    return this.Jn;
  }
  kh() {
    return this.u.j(o.C.wh);
  }
  setSdkAuthenticationSignature(t) {
    const i = this.kh();
    this.u.k(o.C.wh, t);
    const s = A.Yt.Qt;
    new A(s, r).setItem(s.ss.jh, this.gh, t), i !== t && this.Os();
  }
  xh() {
    this.u.ti(o.C.wh);
    const t = A.Yt.Qt;
    new A(t, r).re(t.ss.jh, this.gh);
  }
  subscribeToSdkAuthenticationFailures(t) {
    return this.Qn.It(t);
  }
  qh(t) {
    this.Qn.Dt(t);
  }
  yh() {
    this.Xn.removeAllSubscriptions();
  }
  Bh() {
    this.Zn += 1;
  }
  Gh() {
    return this.Zn;
  }
  Os() {
    this.Zn = 0;
  }
}
