import e from "../managers/braze-instance.js";
import { MAX_PURCHASE_QUANTITY as rr } from "../common/constants.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import tt from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as et } from "../triggers/triggers-provider-factory.js";
import {
  validateCustomProperties as ot,
  validateCustomString as rt,
} from "../util/validation-utils.js";
import s from "../common/event-logger.js";
export function logPurchase(o, n, t, D, u) {
  if (!e.X()) return !1;
  if (
    (null == t && (t = "USD"), null == D && (D = 1), null == o || o.length <= 0)
  )
    return (
      r.error(
        `logPurchase requires a non-empty productId, got "${o}", ignoring.`,
      ),
      !1
    );
  if (!rt(o, "log purchase", "the purchase name")) return !1;
  if (null == n || isNaN(parseFloat(n.toString())))
    return (
      r.error(`logPurchase requires a numeric price, got ${n}, ignoring.`), !1
    );
  const a = parseFloat(n.toString()).toFixed(2);
  if (null == D || isNaN(parseInt(D.toString())))
    return (
      r.error(`logPurchase requires an integer quantity, got ${D}, ignoring.`),
      !1
    );
  const g = parseInt(D.toString());
  if (g < 1 || g > rr)
    return (
      r.error(
        `logPurchase requires a quantity >1 and <${rr}, got ${g}, ignoring.`,
      ),
      !1
    );
  t = null != t ? t.toUpperCase() : t;
  if (
    -1 ===
    [
      "AED",
      "AFN",
      "ALL",
      "AMD",
      "ANG",
      "AOA",
      "ARS",
      "AUD",
      "AWG",
      "AZN",
      "BAM",
      "BBD",
      "BDT",
      "BGN",
      "BHD",
      "BIF",
      "BMD",
      "BND",
      "BOB",
      "BRL",
      "BSD",
      "BTC",
      "BTN",
      "BWP",
      "BYR",
      "BZD",
      "CAD",
      "CDF",
      "CHF",
      "CLF",
      "CLP",
      "CNY",
      "COP",
      "CRC",
      "CUC",
      "CUP",
      "CVE",
      "CZK",
      "DJF",
      "DKK",
      "DOP",
      "DZD",
      "EEK",
      "EGP",
      "ERN",
      "ETB",
      "EUR",
      "FJD",
      "FKP",
      "GBP",
      "GEL",
      "GGP",
      "GHS",
      "GIP",
      "GMD",
      "GNF",
      "GTQ",
      "GYD",
      "HKD",
      "HNL",
      "HRK",
      "HTG",
      "HUF",
      "IDR",
      "ILS",
      "IMP",
      "INR",
      "IQD",
      "IRR",
      "ISK",
      "JEP",
      "JMD",
      "JOD",
      "JPY",
      "KES",
      "KGS",
      "KHR",
      "KMF",
      "KPW",
      "KRW",
      "KWD",
      "KYD",
      "KZT",
      "LAK",
      "LBP",
      "LKR",
      "LRD",
      "LSL",
      "LTL",
      "LVL",
      "LYD",
      "MAD",
      "MDL",
      "MGA",
      "MKD",
      "MMK",
      "MNT",
      "MOP",
      "MRO",
      "MTL",
      "MUR",
      "MVR",
      "MWK",
      "MXN",
      "MYR",
      "MZN",
      "NAD",
      "NGN",
      "NIO",
      "NOK",
      "NPR",
      "NZD",
      "OMR",
      "PAB",
      "PEN",
      "PGK",
      "PHP",
      "PKR",
      "PLN",
      "PYG",
      "QAR",
      "RON",
      "RSD",
      "RUB",
      "RWF",
      "SAR",
      "SBD",
      "SCR",
      "SDG",
      "SEK",
      "SGD",
      "SHP",
      "SLL",
      "SOS",
      "SRD",
      "STD",
      "SVC",
      "SYP",
      "SZL",
      "THB",
      "TJS",
      "TMT",
      "TND",
      "TOP",
      "TRY",
      "TTD",
      "TWD",
      "TZS",
      "UAH",
      "UGX",
      "USD",
      "UYU",
      "UZS",
      "VEF",
      "VND",
      "VUV",
      "WST",
      "XAF",
      "XAG",
      "XAU",
      "XCD",
      "XDR",
      "XOF",
      "XPD",
      "XPF",
      "XPT",
      "YER",
      "ZAR",
      "ZMK",
      "ZMW",
      "ZWL",
    ].indexOf(t)
  )
    return (
      r.error(`logPurchase requires a valid currencyCode, got ${t}, ignoring.`),
      !1
    );
  const [P, R] = ot(
    u,
    "logPurchase",
    "purchaseProperties",
    `log purchase "${o}"`,
    "purchase",
  );
  if (!P) return !1;
  const c = e.ir();
  if (c && c.Dr(o))
    return r.info(`Purchase "${o}" is blocklisted, ignoring.`), !1;
  const l = s.q(i.Pr, { pid: o, c: t, p: a, q: g, pr: R });
  if (l.L) {
    r.info(`Logged ${g} purchase${g > 1 ? "s" : ""} of "${o}" for ${t} ${a}.`);
    for (const r of l.ge) et.rr().fe(tt.Rr, [o, u], r);
  }
  return l.L;
}
