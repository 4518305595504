import { logger as r } from "../../shared-lib/index.js";
export default class FeatureFlag {
  constructor(t, e = !1, r = {}, i) {
    (this.id = t),
      (this.enabled = e),
      (this.properties = r),
      (this.trackingString = i),
      (this.id = t),
      (this.enabled = e),
      (this.properties = r),
      (this.trackingString = i);
  }
  he(t, e, r) {
    const i = this.properties[t];
    return null == i ? (this.ye(t), null) : e(i) ? i.value : (this.Pe(r), null);
  }
  getStringProperty(t) {
    return this.he(t, this.be, "string");
  }
  getNumberProperty(t) {
    return this.he(t, this.Ie, "number");
  }
  getBooleanProperty(t) {
    return this.he(t, this.de, "boolean");
  }
  getImageProperty(t) {
    return this.he(t, this.Te, "image");
  }
  getJsonProperty(t) {
    return this.he(t, this.Ee, "jsonobject");
  }
  getTimestampProperty(t) {
    return this.he(t, this.Ne, "datetime");
  }
  Y() {
    const t = {};
    return (
      (t[FeatureFlag.tt.ht] = this.id),
      (t[FeatureFlag.tt.le] = this.enabled),
      (t[FeatureFlag.tt.pe] = this.properties),
      (t[FeatureFlag.tt.Fe] = this.trackingString),
      t
    );
  }
  Pe(t) {
    r.info(`Property is not of type ${t}.`);
  }
  ye(t) {
    r.info(`${t} not found in feature flag properties.`);
  }
  be(t) {
    return "string" === t.type && "string" == typeof t.value;
  }
  Ie(t) {
    return "number" === t.type && "number" == typeof t.value;
  }
  de(t) {
    return "boolean" === t.type && "boolean" == typeof t.value;
  }
  Te(t) {
    return "image" === t.type && "string" == typeof t.value;
  }
  Ee(t) {
    return (
      "jsonobject" === t.type &&
      "object" == typeof t.value &&
      t.value.constructor == Object
    );
  }
  Ne(t) {
    return "datetime" === t.type && "number" == typeof t.value;
  }
}
(FeatureFlag.tt = { ht: "id", le: "e", pe: "pr", Fe: "fts" }),
  (FeatureFlag.Rt = { ht: "id", le: "enabled", pe: "properties", Fe: "fts" });
