const yt = {
  pn: () =>
    "serviceWorker" in navigator &&
    "undefined" != typeof ServiceWorkerRegistration &&
    "showNotification" in ServiceWorkerRegistration.prototype &&
    "PushManager" in window,
  yn: () =>
    "safari" in window &&
    "pushNotification" in window.safari &&
    "function" == typeof window.safari.pushNotification.permission &&
    "function" == typeof window.safari.pushNotification.requestPermission,
  isPushSupported: () => yt.pn() || yt.yn(),
  isPushBlocked: () => {
    const i =
        yt.isPushSupported() &&
        "Notification" in window &&
        null != window.Notification &&
        null != window.Notification.permission &&
        "denied" === window.Notification.permission,
      n =
        yt.isPushSupported() &&
        (!("Notification" in window) || null == window.Notification);
    return i || n;
  },
  isPushPermissionGranted: () =>
    yt.isPushSupported() &&
    "Notification" in window &&
    null != window.Notification &&
    null != window.Notification.permission &&
    "granted" === window.Notification.permission,
  Qi: () =>
    !yt.isPushBlocked() &&
    yt.isPushSupported() &&
    !yt.isPushPermissionGranted(),
};
export default yt;
