import { ControlCard } from "./models/index.js";
import s from "../common/event-logger.js";
import t from "../models/request-result.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
export default class a {
  constructor(s) {
    (this.u = s), (this.u = s);
  }
  h(n, e) {
    const l = new t();
    if ((n.p(), null == n.url || "" === n.url))
      return (
        r.info(`Card ${n.id} has no url. Not logging click to Braze servers.`),
        l
      );
    if (e && n.id && this.u) {
      const s = this.u.j(o.C.v) || {};
      (s[n.id] = !0), this.u.k(o.C.v, s);
    }
    const u = this.D([n]);
    if (null == u) return l;
    const c = e ? i.I : i.$;
    return s.q(c, u);
  }
  B(n) {
    const e = new t();
    if (!n.N())
      return (
        r.info(
          `Card ${n.id} refused this dismissal. Ignoring analytics event.`,
        ),
        e
      );
    if (n.id && this.u) {
      const s = this.u.j(o.C.A) || {};
      (s[n.id] = !0), this.u.k(o.C.A, s);
    }
    const l = this.D([n]);
    return null == l ? e : s.q(i.F, l);
  }
  G(n, r) {
    const e = new t(!0),
      l = [],
      u = [];
    let c = {};
    this.u && (c = r ? this.u.j(o.C.H) || {} : this.u.j(o.C.J) || {});
    for (const s of n)
      s.K(),
        s instanceof ControlCard ? u.push(s) : l.push(s),
        s.id && (c[s.id] = !0);
    const h = this.D(l),
      a = this.D(u);
    if (null == h && null == a) return (e.L = !1), e;
    if ((this.u && (r ? this.u.k(o.C.H, c) : this.u.k(o.C.J, c)), null != h)) {
      const t = r ? i.M : i.O,
        n = s.q(t, h);
      e.P(n);
    }
    if (null != a && r) {
      const t = s.q(i.R, a);
      e.P(t);
    }
    return e;
  }
  D(s) {
    let t,
      n = null;
    for (let r = 0; r < s.length; r++)
      (t = s[r].id),
        null != t &&
          "" !== t &&
          ((n = n || {}), (n.ids = n.ids || []), n.ids.push(t));
    return n;
  }
}
