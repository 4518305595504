export default class ts {
  constructor(t) {
    (this.Bl = t), (this.Bl = t);
  }
  Jl(t) {
    return null == this.Bl || this.Bl === t[0];
  }
  static fromJson(t) {
    return new ts(t ? t.event_name : null);
  }
  Y() {
    return this.Bl;
  }
}
