import { Guid as p, logger as r } from "../../shared-lib/index.js";
import { isArray as j } from "../util/code-utils.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export default class _t {
  constructor(t, e, i) {
    (this.eu = t),
      null == t && (t = p.W()),
      !i || isNaN(i) ? (this.Dl = new Date().valueOf()) : (this.Dl = i),
      (this.eu = t),
      (this.Wl = new Date().valueOf()),
      (this.Hl = e);
  }
  Y() {
    return `g:${encodeURIComponent(this.eu)}|e:${this.Hl}|c:${this.Dl}|l:${
      this.Wl
    }`;
  }
  static hE(t) {
    if ("string" != typeof t) return null;
    const e = t.lastIndexOf("|e:"),
      i = t.substring(0, e),
      r = i.split("g:")[1];
    let n;
    return (
      (n = /[|:]/.test(r) ? encodeURIComponent(r) : r),
      (t = t.replace(i, `g:${n}`))
    );
  }
  static qn(t) {
    let e;
    if ("string" == typeof t)
      try {
        const i = t.split("|");
        if (!j(i) || 4 !== i.length) return null;
        const r = (t) => t.split(":")[1],
          n = (t) => {
            const e = parseInt(r(t));
            if (!isNaN(e)) return e;
          };
        (e = new _t(decodeURIComponent(r(i[0])), n(i[1]), n(i[2]))),
          (e.Wl = n(i[3]));
      } catch (e) {
        r.info(
          `Unable to parse cookie string ${t}, failed with error: ${si(e)}`,
        );
      }
    else {
      if (null == t || null == t.g) return null;
      (e = new _t(t.g, t.e, t.c)), (e.Wl = t.l);
    }
    return e;
  }
}
