import Card from "./card.js";
export default class ControlCard extends Card {
  constructor(l, t, s, i, n, r) {
    super(l, t, null, null, null, null, s, null, i, null, null, null, n, r),
      (this.isControl = !0),
      (this._ = "ab-control-card"),
      (this.S = !1);
  }
  Y() {
    const l = {};
    return (
      (l[Card.tt.Z] = Card.it.Ut),
      (l[Card.tt.ht] = this.id),
      (l[Card.tt.et] = this.viewed),
      (l[Card.tt.nt] = this.updated),
      (l[Card.tt.lt] = this.expiresAt),
      (l[Card.tt.bt] = this.extras),
      (l[Card.tt.gt] = this.pinned),
      (l[Card.tt.kt] = this.test),
      l
    );
  }
}
