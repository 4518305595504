import e, { OPTIONS as L } from "../../managers/braze-instance.js";
import { ContentCards, subscribeToContentCardsUpdates } from "../index.js";
import g from "../content-cards-provider-factory.js";
import {
  destroyFeedHtml as z,
  detectFeedImpressions as q,
  feedToHtml as I,
  LAST_REQUESTED_REFRESH_DATA_ATTRIBUTE as M,
  refreshFeed as $,
  registerFeedSubscriptionId as B,
  updateFeedCards as F,
} from "../../common/feed-display.js";
import { setCardHeight as G } from "../../Card/display/card-display.js";
import { setupFeedUI as H } from "../../ui/js/index.js";
import { logger as r } from "../../../shared-lib/index.js";
export function showContentCards(n, t) {
  if (!e.X()) return;
  H();
  let o = !1;
  null == n && ((n = document.body), (o = !0));
  const i = e.nn(L.tn) || e.nn(L.en) || !1,
    a = g.rr().Us(!1);
  "function" == typeof t && F(a, t(a.cards.slice()), a.lastUpdated, null, i);
  const s = I(a, i, o),
    f = g.rr(),
    l = f.fs();
  (null == a.lastUpdated ||
    new Date().valueOf() - a.lastUpdated.valueOf() > ContentCards.ur) &&
    (null == l || new Date().valueOf() - l > ContentCards.ur) &&
    (r.info(
      `Cached content cards were older than max TTL of ${ContentCards.ur} ms, requesting an update from the server.`,
    ),
    $(a, s),
    f.ds(new Date().valueOf()));
  const c = new Date().valueOf(),
    u = subscribeToContentCardsUpdates(function (n) {
      const e = s.querySelectorAll(".ab-refresh-button")[0];
      if (null != e) {
        let n = 500,
          t = (n -= new Date().valueOf() - c);
        const o = s.getAttribute(M);
        o && ((t = parseInt(o)), isNaN(t) || (n -= new Date().valueOf() - t)),
          setTimeout(
            function () {
              e.className = e.className.replace(/fa-spin/g, "");
            },
            Math.max(n, 0),
          );
      }
      let o = n.cards;
      "function" == typeof t && (o = t(o.slice())),
        F(a, o, n.lastUpdated, s, i);
    });
  B(u, s);
  const d = function (n) {
    const t = n.querySelectorAll(".ab-feed");
    let e = null;
    for (let o = 0; o < t.length; o++) t[o].parentNode === n && (e = t[o]);
    null != e
      ? (z(e), null != e.parentNode && e.parentNode.replaceChild(s, e))
      : n.appendChild(s),
      setTimeout(function () {
        s.className = s.className.replace("ab-hide", "ab-show");
      }, 0),
      o && s.focus(),
      q(a, s),
      G(a.cards, n);
  };
  var m;
  null != n
    ? d(n)
    : (window.onload =
        ((m = window.onload),
        function () {
          "function" == typeof m && m(new Event("oldLoad")), d(document.body);
        }));
}
