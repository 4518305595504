import C from "../util/net.js";
import e from "../managers/braze-instance.js";
import ControlMessage from "./models/control-message.js";
import HtmlMessage from "./models/html-message.js";
import InAppMessage from "./models/in-app-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import s from "../common/event-logger.js";
import { newInAppMessageFromJson as pt } from "./in-app-message-factory.js";
import { randomInclusive as U } from "../util/math.js";
import t from "../models/request-result.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import T from "../managers/subscription-manager.js";
import gt from "../triggers/models/trigger.js";
import { BRAZE_ACTION_URI_REGEX as Z } from "../util/validation-utils.js";
import {
  containsPushPrimerBrazeAction as ft,
  containsUnknownBrazeAction as jt,
  getDecodedBrazeAction as W,
  ineligibleBrazeActionURLErrorMessage as vt,
  INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES as bt,
} from "../util/braze-actions.js";
import yt from "../Push/utils/push-utils.js";
import D from "../util/request-header-utils.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import { FullScreenMessage, ModalMessage, SlideUpMessage } from "./index.js";
export default class aa {
  constructor(t, s, i, r) {
    (this.qt = t),
      (this.ri = s),
      (this.u = i),
      (this.wt = r),
      (this.qt = t),
      (this.ri = s),
      (this.u = i),
      (this.wt = r),
      (this._e = new T()),
      e.$t(this._e),
      (this.Xe = 1e3),
      (this.We = 6e4),
      (this.Ye = null);
  }
  Ze() {
    return this._e;
  }
  Mi(t) {
    return this._e.It(t);
  }
  _i() {
    return this.Ye;
  }
  Pi(t) {
    this.Ye = t;
  }
  q(e, n, o, a) {
    const l = new t();
    let u;
    if (e instanceof ControlMessage) u = { trigger_ids: [e.triggerId] };
    else {
      if (n === i.$i || (e instanceof HtmlMessage && n === i.Ei)) {
        if (!e.p(a))
          return (
            r.info(
              "This in-app message has already received a click. Ignoring analytics event.",
            ),
            l
          );
      } else if (n === i.Gi) {
        if (!e.K())
          return (
            r.info(
              "This in-app message has already received an impression. Ignoring analytics event.",
            ),
            l
          );
      }
      u = this.Oi(e);
    }
    return null == u
      ? l
      : (e.messageExtras && (u.message_extras = e.messageExtras),
        null != o && (u.bid = o),
        s.q(n, u));
  }
  Xi(e, n) {
    const o = new t();
    if (!e.p())
      return (
        r.info(
          "This in-app message button has already received a click. Ignoring analytics event.",
        ),
        o
      );
    const a = this.Oi(n);
    return null == a
      ? o
      : e.id === InAppMessageButton.Hi
      ? (r.info(
          "This in-app message button does not have a tracking id. Not logging event to Braze servers.",
        ),
        o)
      : (null != e.id && (a.bid = e.id), s.q(i.Ei, a));
  }
  Ji(t) {
    const e = t.messageFields;
    return (null != e && e.is_push_primer) || !1;
  }
  Ki(t) {
    if (!(t instanceof InAppMessage)) return;
    const e = (t) => {
      if (!t) return;
      const e = W(t);
      return jt(e)
        ? vt(bt.Li, "In-App Message")
        : ft(e) && !yt.Qi()
        ? vt(bt.Vi, "In-App Message")
        : void 0;
    };
    if (this.Ji(t) && !yt.Qi())
      return "In-App Message contains a push prompt, but is not eligible for a push prompt. Ignoring.";
    const s = t.buttons || [];
    let i;
    for (const t of s)
      if (
        t.clickAction === InAppMessage.ClickAction.URI &&
        t.uri &&
        Z.test(t.uri) &&
        ((i = e(t.uri)), i)
      )
        return i;
    return t.clickAction === InAppMessage.ClickAction.URI &&
      t.uri &&
      Z.test(t.uri)
      ? e(t.uri)
      : void 0;
  }
  Wi(t, e, s, i) {
    const n = this.qt;
    if (!n) return;
    const o = n.Yi(!1, !1),
      a = n.Js(o);
    (a.template = { trigger_id: t.triggerId, trigger_event_type: e }),
      null != s && (a.template.data = s.Zi());
    const l = n.$s(a, D.Ps.Ir);
    n.Xs(
      a,
      (n = -1) => {
        if (!this.qt) return;
        const o = new Date().valueOf();
        D._s(this.u, D.Ps.Ir, o),
          -1 !== n && l.push(["X-Braze-Req-Tokens-Remaining", n.toString()]),
          C.Gs({
            url: `${this.qt.Hs()}/template/`,
            data: a,
            headers: l,
            L: (e) => {
              if ((D.Qs(this.u, D.Ps.Ir, 1), !this.qt.Ks(a, e, l)))
                return void ("function" == typeof t.Mr && t.Mr());
              if ((this.qt.Os(), null == e || null == e.templated_message))
                return;
              const s = e.templated_message;
              if (s.type !== gt.Br.Tr) return;
              const i = pt(s.data);
              if (null == i) return;
              const n = this.Ki(i);
              if (n)
                return r.error(n), void ("function" == typeof t.Mr && t.Mr());
              "function" == typeof t._r && t._r(i);
            },
            error: (r) => {
              let n = `getting user personalization for message ${t.triggerId}`;
              if (new Date().valueOf() - t.Er < t.Gr) {
                D.Ys(this.u, D.Ps.Ir);
                const r = Math.min(t.Gr, this.We),
                  o = this.Xe;
                null == i && (i = o);
                const a = Math.min(r, U(o, 3 * i));
                (n += `. Retrying in ${a} ms`),
                  setTimeout(() => {
                    this.Wi(t, e, s, a);
                  }, a);
              }
              this.qt.Vs(r, n);
            },
          });
      },
      D.Ps.Ir,
    );
  }
  Oi(t) {
    if (null == t.triggerId)
      return (
        r.info(
          "The in-app message has no analytics id. Not logging event to Braze servers.",
        ),
        null
      );
    const e = {};
    return null != t.triggerId && (e.trigger_ids = [t.triggerId]), e;
  }
  je(t) {
    return (
      !!this.u &&
      !(
        !(t && t instanceof InAppMessage && t.constructor !== InAppMessage) ||
        t instanceof ControlMessage
      ) &&
      this.u.k(o.C.Nr, t.Y())
    );
  }
  Se() {
    if (!this.u) return null;
    const t = this.u.j(o.C.Nr);
    if (!t) return null;
    let e;
    switch (t.type) {
      case InAppMessage.Be.Or:
        e = FullScreenMessage.Xr(t);
        break;
      case InAppMessage.Be.Hr:
      case InAppMessage.Be.Me:
      case InAppMessage.Be.Ve:
        e = HtmlMessage.Xr(t);
        break;
      case InAppMessage.Be.Jr:
      case InAppMessage.Be.Ue:
        e = ModalMessage.Xr(t);
        break;
      case InAppMessage.Be.Kr:
        e = SlideUpMessage.Xr(t);
    }
    return e && this.Lr(), e;
  }
  Lr() {
    this.u && this.u.ti(o.C.Nr);
  }
}
