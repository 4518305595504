import FullScreenMessage from "../models/full-screen-message.js";
import HtmlMessage from "../models/html-message.js";
import ModalMessage from "../models/modal-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
export default function me(e) {
  let s = "";
  return (
    e.animateIn && (s += " ab-animate-in"),
    e.animateOut && (s += " ab-animate-out"),
    e instanceof FullScreenMessage
      ? (s += " ab-effect-fullscreen")
      : e instanceof HtmlMessage
      ? (s += " ab-effect-html")
      : e instanceof ModalMessage
      ? (s += " ab-effect-modal")
      : e instanceof SlideUpMessage && (s += " ab-effect-slide"),
    s
  );
}
