import { Card } from "./models/index.js";
import n from "./card-manager-factory.js";
import { MUST_BE_CARD_WARNING_SUFFIX as f } from "../common/constants.js";
import e from "../managers/braze-instance.js";
import { logger as r } from "../../shared-lib/index.js";
export function logCardDismissal(o) {
  return (
    !!e.X() && (o instanceof Card ? n.m().B(o).L : (r.error("card " + f), !1))
  );
}
