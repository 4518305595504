import e from "../managers/braze-instance.js";
import ee from "./feed-provider.js";
const re = {
  t: !1,
  provider: null,
  rr: () => (
    re.o(),
    re.provider || ((re.provider = new ee(e.l(), e.cr())), e.ar(re.provider)),
    re.provider
  ),
  o: () => {
    re.t || (e.g(re), (re.t = !0));
  },
  destroy: () => {
    (re.provider = null), (re.t = !1);
  },
};
export default re;
