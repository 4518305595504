import ve from "../models/braze-event.js";
import _t from "../models/identifier.js";
import {
  isArray as j,
  keys as to,
  validateValueIsFromEnum as J,
  values as Rt,
} from "../util/code-utils.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import { User } from "../User/index.js";
import X from "../util/browser-detector.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export const STORAGE_KEYS = {
  iu: {
    su: "ab.storage.userId",
    Uo: "ab.storage.deviceId",
    El: "ab.storage.sessionId",
  },
  C: {
    ec: "ab.test",
    tE: "ab.storage.events",
    eE: "ab.storage.attributes",
    sE: "ab.storage.attributes.anonymous_user",
    Fa: "ab.storage.device",
    Jh: "ab.storage.sdk_metadata",
    Qh: "ab.storage.session_id_for_cached_metadata",
    In: "ab.storage.pushToken",
    Ui: "ab.storage.newsFeed",
    ki: "ab.storage.lastNewsFeedRefresh",
    J: "ab.storage.cardImpressions",
    ul: "ab.storage.serverConfig",
    rE: "ab.storage.triggers",
    oE: "ab.storage.triggers.ts",
    Nl: "ab.storage.messagingSessionStart",
    ws: "ab.storage.cc",
    gs: "ab.storage.ccLastFullSync",
    ys: "ab.storage.ccLastCardUpdated",
    nE: "ab.storage.ccRateLimitCurrentTokenCount",
    fu: "ab.storage.globalRateLimitCurrentTokenCount",
    gu: "ab.storage.dynamicRateLimitCurrentTokenCount",
    v: "ab.storage.ccClicks",
    H: "ab.storage.ccImpressions",
    A: "ab.storage.ccDismissals",
    aE: "ab.storage.lastDisplayedTriggerTimesById",
    iE: "ab.storage.lastDisplayedTriggerTime",
    EE: "ab.storage.triggerFireInstancesById",
    wh: "ab.storage.signature",
    As: "ab.storage.brazeSyncRetryCount",
    Ss: "ab.storage.sdkVersion",
    gi: "ab.storage.ff",
    vi: "ab.storage.ffImpressions",
    xi: "ab.storage.ffLastRefreshAt",
    Di: "ab.storage.ff.sessionId",
    lE: "ab.storage.lastReqToEndpoint",
    SE: "ab.storage.requestAttempts",
    Nr: "ab.storage.deferredIam",
    Za: "ab.storage.lastSdkReq",
    _E: "ab.storage.alias",
  },
  se: "ab.optOut",
};
export default class Q {
  constructor(t, e) {
    (this.uE = t), (this.TE = e), (this.uE = t), (this.TE = e);
  }
  Vh(t) {
    const e = to(STORAGE_KEYS.iu),
      s = new Q.ee(t);
    for (const t of e) s.remove(STORAGE_KEYS.iu[t]);
  }
  uu(t, e) {
    let s = null;
    null != e && e instanceof _t && (s = e.Y()), this.uE.store(t, s);
  }
  cE(t) {
    const e = this.tu(t);
    null != e && ((e.Wl = new Date().valueOf()), this.uu(t, e));
  }
  tu(t) {
    const e = this.uE.br(t),
      s = ((t) => {
        let e;
        try {
          e = JSON.parse(t);
        } catch (t) {
          e = null;
        }
        return e;
      })(e);
    let r;
    if (s) (r = _t.qn(s) || null), r && this.uu(t, r);
    else {
      const s = _t.hE(e);
      (r = _t.qn(s) || null), s !== e && r && this.uu(t, r);
    }
    return r;
  }
  Al(t) {
    this.uE.remove(t);
  }
  Kh() {
    const t = to(STORAGE_KEYS.iu);
    let e;
    for (const s of t)
      (e = this.tu(STORAGE_KEYS.iu[s])),
        null != e && this.uu(STORAGE_KEYS.iu[s], e);
  }
  zo(t) {
    let e;
    if (null == t || 0 === t.length) return !1;
    e = j(t) ? t : [t];
    let s = this.TE.br(STORAGE_KEYS.C.tE);
    (null != s && j(s)) || (s = []);
    for (let t = 0; t < e.length; t++) s.push(e[t].Y());
    return this.TE.store(STORAGE_KEYS.C.tE, s);
  }
  kl(t) {
    return null != t && this.zo([t]);
  }
  AE() {
    let t = this.TE.br(STORAGE_KEYS.C.tE);
    this.TE.remove(STORAGE_KEYS.C.tE), null == t && (t = []);
    const e = [];
    let s = !1,
      r = null;
    if (j(t))
      for (let s = 0; s < t.length; s++)
        ve.RE(t[s]) ? e.push(ve.qn(t[s])) : (r = s);
    else s = !0;
    if (s || null != r) {
      let o = "Stored events could not be deserialized as Events";
      s &&
        (o += ", was " + Object.prototype.toString.call(t) + " not an array"),
        null != r &&
          (o += ", value at index " + r + " does not look like an event"),
        (o +=
          ", serialized values were of type " +
          typeof t +
          ": " +
          JSON.stringify(t)),
        e.push(new ve(null, i.qs, new Date().valueOf(), null, { e: o }));
    }
    return e;
  }
  k(t, e) {
    return (
      !!J(
        STORAGE_KEYS.C,
        t,
        "StorageManager cannot store object.",
        "STORAGE_KEYS.OBJECTS",
      ) && this.TE.store(t, e)
    );
  }
  j(t) {
    return (
      !!J(
        STORAGE_KEYS.C,
        t,
        "StorageManager cannot retrieve object.",
        "STORAGE_KEYS.OBJECTS",
      ) && this.TE.br(t)
    );
  }
  ti(t) {
    return (
      !!J(
        STORAGE_KEYS.C,
        t,
        "StorageManager cannot remove object.",
        "STORAGE_KEYS.OBJECTS",
      ) && (this.TE.remove(t), !0)
    );
  }
  clearData() {
    const t = to(STORAGE_KEYS.iu),
      e = to(STORAGE_KEYS.C);
    for (let e = 0; e < t.length; e++) {
      const s = t[e];
      this.uE.remove(STORAGE_KEYS.iu[s]);
    }
    for (let t = 0; t < e.length; t++) {
      const s = e[t];
      this.TE.remove(STORAGE_KEYS.C[s]);
    }
  }
  gE(t) {
    return t || STORAGE_KEYS.C.sE;
  }
  Ea(t) {
    let e = this.TE.br(STORAGE_KEYS.C.eE);
    null == e && (e = {});
    const s = this.gE(t[User.Yn]),
      r = e[s];
    for (const o in t)
      o !== User.Yn &&
        (null == e[s] || (r && null == r[o])) &&
        this.mu(t[User.Yn], o, t[o]);
  }
  mu(t, e, s) {
    let r = this.TE.br(STORAGE_KEYS.C.eE);
    null == r && (r = {});
    const o = this.gE(t);
    let n = r[o];
    if (
      (null == n && ((n = {}), null != t && (n[User.Yn] = t)), e === User.lu)
    ) {
      null == n[e] && (n[e] = {});
      for (const t in s) n[e][t] = s[t];
    } else n[e] = s;
    return (r[o] = n), this.TE.store(STORAGE_KEYS.C.eE, r);
  }
  OE() {
    const t = this.TE.br(STORAGE_KEYS.C.eE);
    this.TE.remove(STORAGE_KEYS.C.eE);
    const e = [];
    for (const s in t) null != t[s] && e.push(t[s]);
    return e;
  }
  ru(t) {
    const e = this.TE.br(STORAGE_KEYS.C.eE);
    if (null != e) {
      const s = this.gE(null),
        r = e[s];
      null != r &&
        ((e[s] = void 0),
        this.TE.store(STORAGE_KEYS.C.eE, e),
        (r[User.Yn] = t),
        this.Ea(r));
    }
    const s = this.tu(STORAGE_KEYS.iu.El);
    let r = null;
    null != s && (r = s.eu);
    const o = this.AE();
    if (null != o)
      for (let e = 0; e < o.length; e++) {
        const s = o[e];
        null == s.userId && s.sessionId == r && (s.userId = t), this.kl(s);
      }
  }
  dE() {
    return this.TE.fE;
  }
}
(Q.rc = class {
  constructor(t) {
    (this.rn = t), (this.rn = t), (this.fE = X.IE() ? 3 : 10);
  }
  bE(t) {
    return t + "." + this.rn;
  }
  store(t, e) {
    const s = { v: e };
    try {
      return localStorage.setItem(this.bE(t), JSON.stringify(s)), !0;
    } catch (t) {
      return r.info("Storage failure: " + si(t)), !1;
    }
  }
  br(t) {
    try {
      let e = null;
      const s = localStorage.getItem(this.bE(t));
      return null != s && (e = JSON.parse(s)), null == e ? null : e.v;
    } catch (t) {
      return r.info("Storage retrieval failure: " + si(t)), null;
    }
  }
  remove(t) {
    try {
      localStorage.removeItem(this.bE(t));
    } catch (t) {
      return r.info("Storage removal failure: " + si(t)), !1;
    }
  }
}),
  (Q.ac = class {
    constructor() {
      (this.mE = {}), (this.KE = 5242880), (this.fE = 3);
    }
    store(t, e) {
      const s = { value: e },
        o = this.YE(e);
      return o > this.KE
        ? (r.info(
            "Storage failure: object is ≈" +
              o +
              " bytes which is greater than the max of " +
              this.KE,
          ),
          !1)
        : ((this.mE[t] = s), !0);
    }
    YE(t) {
      const e = [],
        s = [t];
      let r = 0;
      for (; s.length; ) {
        const t = s.pop();
        if ("boolean" == typeof t) r += 4;
        else if ("string" == typeof t) r += 2 * t.length;
        else if ("number" == typeof t) r += 8;
        else if ("object" == typeof t && -1 === e.indexOf(t)) {
          let r, o;
          e.push(t);
          for (const e in t) (o = t), (r = e), s.push(o[r]);
        }
      }
      return r;
    }
    br(t) {
      const e = this.mE[t];
      return null == e ? null : e.value;
    }
    remove(t) {
      this.mE[t] = null;
    }
  }),
  (Q.ee = class {
    constructor(t, e) {
      (this.rn = t),
        (this.NE = e),
        (this.rn = t),
        (this.CE = this.GE()),
        (this.DE = 576e3),
        (this.NE = !!e);
    }
    bE(t) {
      return null != this.rn ? t + "." + this.rn : t;
    }
    GE() {
      let t = 0,
        e = document.location.hostname;
      const s = e.split("."),
        r = "ab._gd";
      for (; t < s.length - 1 && -1 === document.cookie.indexOf(r + "=" + r); )
        t++,
          (e = "." + s.slice(-1 - t).join(".")),
          (document.cookie = r + "=" + r + ";domain=" + e + ";");
      return (
        (document.cookie =
          r + "=;expires=" + new Date(0).toUTCString() + ";domain=" + e + ";"),
        e
      );
    }
    ne() {
      const t = new Date();
      return t.setTime(t.getTime() + 60 * this.DE * 1e3), t.getFullYear();
    }
    ME() {
      const t = Rt(STORAGE_KEYS.iu),
        e = document.cookie.split(";");
      for (let s = 0; s < e.length; s++) {
        let r = e[s];
        for (; " " === r.charAt(0); ) r = r.substring(1);
        let o = !1;
        for (let e = 0; e < t.length; e++)
          if (0 === r.indexOf(t[e])) {
            o = !0;
            break;
          }
        if (o) {
          const t = r.split("=")[0];
          -1 === t.indexOf("." + this.rn) && this.pE(t);
        }
      }
    }
    store(t, e) {
      this.ME();
      const s = new Date();
      s.setTime(s.getTime() + 60 * this.DE * 1e3);
      const o = "expires=" + s.toUTCString(),
        n = "domain=" + this.CE;
      let a;
      a = this.NE ? e : encodeURIComponent(e);
      const i = this.bE(t) + "=" + a + ";" + o + ";" + n + ";path=/";
      return i.length >= 4093
        ? (r.info(
            "Storage failure: string is " +
              i.length +
              " chars which is too large to store as a cookie.",
          ),
          !1)
        : ((document.cookie = i), !0);
    }
    br(t) {
      const e = [],
        s = this.bE(t) + "=",
        o = document.cookie.split(";");
      for (let n = 0; n < o.length; n++) {
        let a = o[n];
        for (; " " === a.charAt(0); ) a = a.substring(1);
        if (0 === a.indexOf(s))
          try {
            let t;
            (t = this.NE
              ? a.substring(s.length, a.length)
              : decodeURIComponent(a.substring(s.length, a.length))),
              e.push(t);
          } catch (e) {
            return (
              r.info("Storage retrieval failure: " + si(e)),
              this.remove(t),
              null
            );
          }
      }
      return e.length > 0 ? e[e.length - 1] : null;
    }
    remove(t) {
      this.pE(this.bE(t));
    }
    pE(t) {
      const e = t + "=;expires=" + new Date(0).toUTCString();
      (document.cookie = e), (document.cookie = e + ";path=/");
      const s = e + ";domain=" + this.CE;
      (document.cookie = s), (document.cookie = s + ";path=/");
    }
  }),
  (Q.tc = class {
    constructor(t, e, s) {
      (this.rn = t),
        (this.vE = []),
        e && this.vE.push(new Q.ee(t)),
        s && this.vE.push(new Q.rc(t)),
        this.vE.push(new Q.ac());
    }
    store(t, e) {
      let s = !0;
      for (let r = 0; r < this.vE.length; r++) s = this.vE[r].store(t, e) && s;
      return s;
    }
    br(t) {
      for (let e = 0; e < this.vE.length; e++) {
        const s = this.vE[e].br(t);
        if (null != s) return s;
      }
      return null;
    }
    remove(t) {
      new Q.ee(this.rn).remove(t);
      for (let e = 0; e < this.vE.length; e++) this.vE[e].remove(t);
    }
  });
