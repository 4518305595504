import e from "../managers/braze-instance.js";
import ir from "./feature-flags-provider-factory.js";
import { getAllFeatureFlags } from "./get-all-feature-flags.js";
export function subscribeToFeatureFlagsUpdates(r) {
  if (!e.X()) return;
  const t = ir.rr();
  if (t.qi()) {
    const t = getAllFeatureFlags();
    t && "function" == typeof r && r(t);
  }
  return t.Zs(r);
}
