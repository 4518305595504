import _t from "../models/identifier.js";
import { getByteLength as O } from "../util/string-utils.js";
import ti from "../models/push-token.js";
import { logger as r, IndexedDBAdapter as A } from "../../shared-lib/index.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import { User } from "../User/index.js";
export default class kt {
  constructor(t, s) {
    (this.yt = t), (this.u = s), (this.yt = t), (this.u = s);
  }
  getUserId() {
    const t = this.u.tu(o.iu.su);
    if (null == t) return null;
    let s = t.eu,
      i = O(s);
    if (i > User.lr) {
      for (; i > User.lr; ) (s = s.slice(0, s.length - 1)), (i = O(s));
      (t.eu = s), this.u.uu(o.iu.su, t);
    }
    return s;
  }
  ou(t) {
    const s = null == this.getUserId();
    this.u.uu(o.iu.su, new _t(t)), s && this.u.ru(t);
  }
  setCustomUserAttribute(t, s) {
    if (this.yt.hu(t))
      return (
        r.info('Custom Attribute "' + t + '" is blocklisted, ignoring.'), !1
      );
    const i = {};
    return (i[t] = s), this.nu(User.lu, i, !0);
  }
  nu(t, s, i = !1, e = !1) {
    const u = this.u.mu(this.getUserId(), t, s);
    let o = "",
      h = t,
      n = s;
    return (
      i &&
        ((o = " custom"),
        "object" == typeof s &&
          ((h = Object.keys(s)[0]),
          (n = s[h]),
          "object" == typeof n && (n = JSON.stringify(n, null, 2)))),
      !e && u && r.info(`Logged${o} attribute ${h} with value ${n}`),
      u
    );
  }
  Pn(t, s, i, e, u) {
    this.nu("push_token", t, !1, !0),
      this.nu("custom_push_public_key", i, !1, !0),
      this.nu("custom_push_user_auth", e, !1, !0),
      this.nu("custom_push_vapid_public_key", u, !1, !0);
    const h = A.Yt.Qt,
      n = new A(h, r),
      l = new ti(t, s, i, e, u);
    this.u.k(o.C.In, l.Y()), n.setItem(h.ss.cu, h.ie, !0);
  }
  Sn(t) {
    if (
      (this.nu("push_token", null, !1, !0),
      this.nu("custom_push_public_key", null, !1, !0),
      this.nu("custom_push_user_auth", null, !1, !0),
      this.nu("custom_push_vapid_public_key", null, !1, !0),
      t)
    ) {
      const t = A.Yt.Qt,
        s = new A(t, r);
      this.u.k(o.C.In, !1), s.setItem(t.ss.cu, t.ie, !1);
    }
  }
}
