import y from "../common/base-provider.js";
import e from "../managers/braze-instance.js";
import Feed from "./feed.js";
import {
  newCardFromFeedJson as ht,
  newCardFromSerializedValue as S,
} from "../Card/util/card-factory.js";
import { rehydrateDateAfterJsonization as w } from "../util/date-utils.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import T from "../managers/subscription-manager.js";
export default class ee extends y {
  constructor(t, s) {
    super(),
      (this.u = t),
      (this.zi = s),
      (this.cards = []),
      (this.Ni = null),
      (this.u = t),
      (this.zi = s),
      (this.Jt = new T()),
      e.$t(this.Jt),
      this.Kt();
  }
  Kt() {
    let t = [];
    this.u && (t = this.u.j(o.C.Ui) || []);
    const s = [];
    for (let i = 0; i < t.length; i++) {
      const e = S(t[i]);
      null != e && s.push(e);
    }
    (this.cards = s), this.u && (this.Ni = w(this.u.j(o.C.ki)));
  }
  Ai(t) {
    const s = [];
    let i = null,
      e = {};
    this.u && (e = this.u.j(o.C.J) || {});
    const r = {};
    for (let h = 0; h < t.length; h++) {
      i = t[h];
      const o = ht(i);
      if (null != o) {
        const t = o.id;
        t && e[t] && ((o.viewed = !0), (r[t] = !0)), s.push(o);
      }
    }
    (this.cards = s),
      this.Rs(),
      (this.Ni = new Date()),
      this.u && (this.u.k(o.C.J, r), this.u.k(o.C.ki, this.Ni));
  }
  Rs() {
    var t;
    const s = [];
    for (let t = 0; t < this.cards.length; t++) s.push(this.cards[t].Y());
    null === (t = this.u) || void 0 === t || t.k(o.C.Ui, s);
  }
  Ns(t) {
    null != t &&
      t.feed &&
      (this.Kt(),
      this.Ai(t.feed),
      this.Jt.Dt(new Feed(this.cards.slice(), this.Ni)));
  }
  Bi() {
    this.Kt();
    const t = [],
      s = new Date();
    for (let i = 0; i < this.cards.length; i++) {
      const e = this.cards[i].expiresAt;
      let r = !0;
      null != e && (r = e >= s), r && t.push(this.cards[i]);
    }
    return new Feed(t, this.Ni);
  }
  xs() {
    this.zi && this.zi.requestFeedRefresh();
  }
  Zs(t) {
    return this.Jt.It(t);
  }
  clearData(t) {
    null == t && (t = !1),
      (this.cards = []),
      (this.Ni = null),
      t && this.u && (this.u.ti(o.C.Ui), this.u.ti(o.C.ki)),
      this.Jt.Dt(new Feed(this.cards.slice(), this.Ni));
  }
}
