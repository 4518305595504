import { getUser as ro } from "../../Core/get-user.js";
import { keys as to } from "../../util/code-utils.js";
import { parseQueryStringKeyValues as ut } from "../../util/url-utils.js";
import { WindowUtils as eo } from "../../util/window-utils.js";
import { logInAppMessageHtmlClick } from "../log-in-app-message-html-click.js";
import InAppMessage from "../models/in-app-message.js";
import e from "../../managers/braze-instance.js";
import { logger as r } from "../../../shared-lib/index.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as _ } from "../../common/constants.js";
export const buildHtmlClickHandler = (t, r, e, o) => {
  const s = e.getAttribute("href"),
    n = e.onclick;
  return (i) => {
    if (null != n && "function" == typeof n && !1 === n.bind(e)(i)) return;
    let u = ut(s).abButtonId;
    if (
      ((null != u && "" !== u) || (u = e.getAttribute("id") || void 0),
      null != s && "" !== s && 0 !== s.indexOf("#"))
    ) {
      const n =
          "blank" ===
          (e.getAttribute("target") || "").toLowerCase().replace("_", ""),
        c = o || t.openTarget === InAppMessage.OpenTarget.BLANK || n,
        a = () => {
          logInAppMessageHtmlClick(t, u, s), eo.openUri(s, c, i);
        };
      c ? a() : t.Cr(r, a);
    } else logInAppMessageHtmlClick(t, u, s || void 0);
    return i.stopPropagation(), !1;
  };
};
export const applyNonceToDynamicallyCreatedTags = (t, r, e) => {
  const o = `([\\w]+)\\s*=\\s*document.createElement\\(['"]${e}['"]\\)`,
    s = t.match(new RegExp(o));
  if (s) {
    const e = `${s[1]}.setAttribute("nonce", "${r}")`;
    return `${t.slice(0, s.index + s[0].length)};${e};${t.slice(
      s.index + s[0].length,
    )}`;
  }
  return null;
};
export const buildBrazeBridge = (t, o, s) => {
  const n = {
      closeMessage: function () {
        t.Cr(o);
      },
      logClick: function () {
        logInAppMessageHtmlClick(t, ...arguments);
      },
      display: {},
      web: {},
    },
    requestPushPermission = function () {
      return function () {
        const t = arguments;
        import("../../Push/request-push-permission.js").then((o) => {
          e.so()
            ? o.requestPushPermission(...Array.prototype.slice.call(t))
            : r.error(_);
        });
      };
    },
    i = {
      requestImmediateDataFlush: function () {
        const t = arguments;
        import("../../Core/request-immediate-data-flush.js").then(
          ({ requestImmediateDataFlush: requestImmediateDataFlush }) => {
            e.so()
              ? requestImmediateDataFlush(...Array.prototype.slice.call(t))
              : r.error(_);
          },
        );
      },
      logCustomEvent: function () {
        const t = arguments;
        import("../../Core/log-custom-event.js").then(
          ({ logCustomEvent: logCustomEvent }) => {
            if (!e.so()) return void r.error(_);
            logCustomEvent(...Array.prototype.slice.call(t));
          },
        );
      },
      logPurchase: function () {
        const t = arguments;
        import("../../Core/log-purchase.js").then(
          ({ logPurchase: logPurchase }) => {
            if (!e.so()) return void r.error(_);
            logPurchase(...Array.prototype.slice.call(t));
          },
        );
      },
      unregisterPush: function () {
        const t = arguments;
        import("../../Push/unregister-push.js").then(
          ({ unregisterPush: unregisterPush }) => {
            e.so()
              ? unregisterPush(...Array.prototype.slice.call(t))
              : r.error(_);
          },
        );
      },
      requestPushPermission: requestPushPermission(),
      changeUser: function () {
        const t = arguments;
        import("../../Core/change-user.js").then(
          ({ changeUser: changeUser }) => {
            if (!e.so()) return void r.error(_);
            changeUser(...Array.prototype.slice.call(t));
          },
        );
      },
    },
    u = function (t) {
      return function () {
        i[t](...Array.prototype.slice.call(arguments));
      };
    };
  for (const t of to(i)) n[t] = u(t);
  const c = [
      "setFirstName",
      "setLastName",
      "setEmail",
      "setGender",
      "setDateOfBirth",
      "setCountry",
      "setHomeCity",
      "setEmailNotificationSubscriptionType",
      "setLanguage",
      "addAlias",
      "setPushNotificationSubscriptionType",
      "setPhoneNumber",
      "setCustomUserAttribute",
      "addToCustomAttributeArray",
      "removeFromCustomAttributeArray",
      "incrementCustomUserAttribute",
      "setCustomLocationAttribute",
      "addToSubscriptionGroup",
      "removeFromSubscriptionGroup",
    ],
    a = function (t) {
      return function () {
        const r = ro();
        r && r[t](...Array.prototype.slice.call(arguments));
      };
    },
    m = {};
  for (let t = 0; t < c.length; t++) m[c[t]] = a(c[t]);
  n.getUser = function () {
    return m;
  };
  const l = { showFeed: s },
    f = function (r) {
      return function () {
        const e = arguments;
        t.Cr(o, function () {
          l[r](...Array.prototype.slice.call(e));
        });
      };
    },
    p = n.display;
  for (const t of to(l)) p[t] = f(t);
  const d = { registerAppboyPushMessages: requestPushPermission() },
    g = function (t) {
      return function () {
        d[t](...Array.prototype.slice.call(arguments));
      };
    },
    h = n.web;
  for (const t of to(d)) h[t] = g(t);
  return n;
};
