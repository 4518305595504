import ge from "./base-device-parser.js";
import { Browsers as gi, OperatingSystems as Y } from "./device-constants.js";
export default class oi extends ge {
  constructor() {
    super(), (this.fd = oi.hc(navigator.userAgent || ""));
  }
  ef() {
    return this.fd[0] || "Unknown Browser";
  }
  sf() {
    return this.fd[1] || "Unknown Version";
  }
  Da(r) {
    for (let n = 0; n < r.length; n++) {
      const e = r[n].string;
      let i = oi.ff(e, r[n]);
      if (i)
        return (
          i === Y.Pg && navigator.maxTouchPoints > 1 && (i = Y.io),
          Promise.resolve(i)
        );
    }
    return Promise.resolve(navigator.platform);
  }
  static hc(r) {
    let n,
      e =
        r.match(
          /(samsungbrowser|tizen|roku|konqueror|icab|crios|opera|ucbrowser|chrome|safari|firefox|camino|msie|trident(?=\/))\/?\s*(\.?\d+(\.\d+)*)/i,
        ) || [];
    if (-1 !== r.indexOf("(Web0S; Linux/SmartTV)"))
      return ["LG Smart TV", null];
    if (-1 !== r.indexOf("CrKey")) return ["Chromecast", null];
    if (
      -1 !== r.indexOf("BRAVIA") ||
      -1 !== r.indexOf("SonyCEBrowser") ||
      -1 !== r.indexOf("SonyDTV")
    )
      return ["Sony Smart TV", null];
    if (-1 !== r.indexOf("PhilipsTV")) return ["Philips Smart TV", null];
    if (r.match(/\b(Roku)\b/)) return ["Roku", null];
    if (r.match(/\bAFTM\b/)) return ["Amazon Fire Stick", null];
    if (
      e[1] === gi.rO &&
      ((n = r.match(/\b(OPR|Edge|EdgA|Edg|UCBrowser)\/(\.?\d+(\.\d+)*)/)),
      null != n)
    )
      return (
        (n = n.slice(1)),
        (n[0] = n[0].replace("OPR", gi.oO)),
        (n[0] = n[0].replace("EdgA", gi.eO)),
        "Edg" === n[0] && (n[0] = gi.eO),
        [n[0], n[1]]
      );
    if (
      e[1] === gi.Bg &&
      ((n = r.match(/\b(EdgiOS)\/(\.?\d+(\.\d+)*)/)), null != n)
    )
      return (
        (n = n.slice(1)), (n[0] = n[0].replace("EdgiOS", gi.eO)), [n[0], n[1]]
      );
    if (
      ((e = e[2] ? [e[1], e[2]] : [null, null]),
      e[0] === gi.Bg &&
        null != (n = r.match(/version\/(\.?\d+(\.\d+)*)/i)) &&
        e.splice(1, 1, n[1]),
      null != (n = r.match(/\b(UCBrowser)\/(\.?\d+(\.\d+)*)/)) &&
        e.splice(1, 1, n[2]),
      e[0] === gi.oO && null != (n = r.match(/mini\/(\.?\d+(\.\d+)*)/i)))
    )
      return ["Opera Mini", n[1] || ""];
    if (e[0]) {
      const r = e[0].toLowerCase();
      "crios" === r && (e[0] = gi.rO),
        "tizen" === r && ((e[0] = "Samsung Smart TV"), (e[1] = null)),
        "samsungbrowser" === r && (e[0] = "Samsung Browser");
    }
    return e;
  }
}
