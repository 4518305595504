export default class Gt {
  constructor(s) {
    (this.id = s), (this.id = s);
  }
  Zi() {
    const s = {};
    return (
      null != this.browser && (s.browser = this.browser),
      null != this.Ta && (s.browser_version = this.Ta),
      null != this.os && (s.os_version = this.os),
      null != this.resolution && (s.resolution = this.resolution),
      null != this.language && (s.locale = this.language),
      null != this.timeZone && (s.time_zone = this.timeZone),
      null != this.userAgent && (s.user_agent = this.userAgent),
      s
    );
  }
}
