import e, { OPTIONS as L } from "../../managers/braze-instance.js";
import {
  destroyFeedHtml as z,
  detectFeedImpressions as q,
  feedToHtml as I,
  LAST_REQUESTED_REFRESH_DATA_ATTRIBUTE as M,
  refreshFeed as $,
  registerFeedSubscriptionId as B,
  updateFeedCards as F,
} from "../../common/feed-display.js";
import { Feed, logFeedDisplayed, subscribeToFeedUpdates } from "../index.js";
import re from "../feed-provider-factory.js";
import { intersection as te } from "../../util/code-utils.js";
import { setCardHeight as G } from "../../Card/display/card-display.js";
import { setupFeedUI as H } from "../../ui/js/index.js";
import { logger as r } from "../../../shared-lib/index.js";
export function showFeed(t, n, o) {
  if (!e.X()) return;
  H();
  const i = (e, t) => {
      if (null == t) return e;
      const n = [];
      for (let e = 0; e < t.length; e++) n.push(t[e].toLowerCase());
      const o = [];
      for (let t = 0; t < e.length; t++) {
        const r = [],
          i = e[t].categories || [];
        for (let e = 0; e < i.length; e++) r.push(i[e].toLowerCase());
        te(r, n).length > 0 && o.push(e[t]);
      }
      return o;
    },
    s = e.nn(L.tn) || e.nn(L.en) || !1;
  let l = !1;
  null == t && ((t = document.body), (l = !0));
  let f,
    a = !1;
  null == n
    ? ((f = re.rr().Bi()),
      F(f, i(f.cards, o), f.lastUpdated, null, s),
      (a = !0))
    : (f = new Feed(i(n, o), new Date()));
  const u = I(f, s, l);
  if (a) {
    (null == f.lastUpdated ||
      new Date().valueOf() - f.lastUpdated.valueOf() > Feed.ur) &&
      (r.info(
        `Cached feed was older than max TTL of ${Feed.ur} ms, requesting an update from the server.`,
      ),
      $(f, u));
    const e = new Date().valueOf(),
      t = subscribeToFeedUpdates(function (t) {
        const n = u.querySelectorAll(".ab-refresh-button")[0];
        if (null != n) {
          let t = 500;
          t -= new Date().valueOf() - e;
          const o = u.getAttribute(M);
          if (o) {
            const e = parseInt(o);
            isNaN(e) || (t -= new Date().valueOf() - e);
          }
          setTimeout(
            function () {
              n.className = n.className.replace(/fa-spin/g, "");
            },
            Math.max(t, 0),
          );
        }
        F(f, i(t.cards, o), t.lastUpdated, u, s);
      });
    B(t, u);
  }
  const d = (e) => {
    const t = e.querySelectorAll(".ab-feed");
    let n = null;
    for (let o = 0; o < t.length; o++) t[o].parentNode === e && (n = t[o]);
    null != n
      ? (z(n), n.parentNode && n.parentNode.replaceChild(u, n))
      : e.appendChild(u),
      setTimeout(function () {
        u.className = u.className.replace("ab-hide", "ab-show");
      }, 0),
      l && u.focus(),
      logFeedDisplayed(),
      q(f, u),
      f && G(f.cards, e);
  };
  var m;
  null != t
    ? d(t)
    : (window.onload =
        ((m = window.onload),
        function () {
          "function" == typeof m && m(new Event("oldLoad")), d(document.body);
        }));
}
