const p = {
  W: function () {
    const t = (t = !1) => {
      const n = (Math.random().toString(16) + "000000000").substr(2, 8);
      return t ? "-" + n.substr(0, 4) + "-" + n.substr(4, 4) : n;
    };
    return t() + t(!0) + t(!0) + t();
  },
};
export default p;
