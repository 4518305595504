import { logger as r } from "../../shared-lib/index.js";
import { getErrorMessage as si } from "./error-utils.js";
import { decodeBrazeActions as no } from "./string-utils.js";
import {
  BRAZE_ACTION_URI_REGEX as Z,
  isValidBrazeActionType as io,
  isValidBrazeActionJson as oo,
} from "./validation-utils.js";
export const BRAZE_ACTIONS = {
  types: {
    ro: "container",
    logCustomEvent: "logCustomEvent",
    setEmailNotificationSubscriptionType:
      "setEmailNotificationSubscriptionType",
    setPushNotificationSubscriptionType: "setPushNotificationSubscriptionType",
    setCustomUserAttribute: "setCustomUserAttribute",
    requestPushPermission: "requestPushPermission",
    addToSubscriptionGroup: "addToSubscriptionGroup",
    removeFromSubscriptionGroup: "removeFromSubscriptionGroup",
    addToCustomAttributeArray: "addToCustomAttributeArray",
    removeFromCustomAttributeArray: "removeFromCustomAttributeArray",
    no: "openLink",
    ao: "openLinkInWebView",
  },
  properties: { type: "type", oo: "steps", eo: "args" },
};
export const INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES = {
  Li: "unknownBrazeAction",
  Vi: "noPushPrompt",
};
export const ineligibleBrazeActionURLErrorMessage = (t, o) => {
  switch (t) {
    case INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES.Li:
      return `${o} contains an unknown braze action type and will not be displayed.`;
    case INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES.Vi:
      return `${o} contains a push prompt braze action, but is not eligible for a push prompt. Ignoring.`;
    default:
      return "";
  }
};
export function getDecodedBrazeAction(t) {
  try {
    const o = t.match(Z),
      n = o ? o[0].length : null,
      e = n ? t.substring(n) : null;
    if (null == n || n > t.length - 1 || !e)
      return void r.error(
        `Did not find base64 encoded brazeAction in url to process : ${t}`,
      );
    const i = no(e);
    return i
      ? JSON.parse(i)
      : void r.error(`Failed to decode base64 encoded brazeAction: ${e}`);
  } catch (o) {
    return void r.error(`Failed to process brazeAction URL ${t} : ${si(o)}`);
  }
}
function so(t, o) {
  let r = !1;
  if (o) for (const n of o) if (((r = r || t(n)), r)) return !0;
  return !1;
}
export function containsUnknownBrazeAction(t) {
  const o = BRAZE_ACTIONS.properties.type,
    r = BRAZE_ACTIONS.properties.oo;
  try {
    if (null == t) return !0;
    const n = t[o];
    return n === BRAZE_ACTIONS.types.ro
      ? so(containsUnknownBrazeAction, t[r])
      : !io(n);
  } catch (t) {
    return !0;
  }
}
export function containsPushPrimerBrazeAction(t) {
  if (!t || !oo(t)) return !1;
  const o = BRAZE_ACTIONS.properties.type,
    r = BRAZE_ACTIONS.properties.oo,
    n = t[o];
  return n === BRAZE_ACTIONS.types.ro
    ? so(containsPushPrimerBrazeAction, t[r])
    : n === BRAZE_ACTIONS.types.requestPushPermission;
}
