import Q, { STORAGE_KEYS as o } from "./storage-manager.js";
import { logger as r } from "../../shared-lib/index.js";
const Wt = {
  Wh: function (e, t = !1) {
    let a = !1;
    try {
      if (localStorage && localStorage.getItem)
        try {
          localStorage.setItem(o.C.ec, "true"),
            localStorage.getItem(o.C.ec) &&
              (localStorage.removeItem(o.C.ec), (a = !0));
        } catch (e) {
          if (
            !(
              e instanceof Error &&
              ("QuotaExceededError" === e.name ||
                "NS_ERROR_DOM_QUOTA_REACHED" === e.name) &&
              localStorage.length > 0
            )
          )
            throw e;
          a = !0;
        }
    } catch (e) {
      r.info("Local Storage not supported!");
    }
    const n = Wt.oc(),
      c = new Q.tc(e, n && !t, a);
    let l;
    return (l = a ? new Q.rc(e) : new Q.ac()), new Q(c, l);
  },
  oc: function () {
    return (
      navigator.cookieEnabled ||
      ("cookie" in document &&
        (document.cookie.length > 0 ||
          (document.cookie = "test").indexOf.call(document.cookie, "test") >
            -1))
    );
  },
};
export default Wt;
