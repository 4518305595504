import { rehydrateDateAfterJsonization as w } from "../util/date-utils.js";
export default class ti {
  constructor(t, i, s, l, h) {
    (this.endpoint = t),
      (this.Vn = i),
      (this.publicKey = s),
      (this.Vl = l),
      (this.cl = h),
      (this.endpoint = t || null),
      (this.Vn = i || null),
      (this.publicKey = s || null),
      (this.Vl = l || null),
      (this.cl = h || null);
  }
  Y() {
    return {
      e: this.endpoint,
      c: this.Vn,
      p: this.publicKey,
      u: this.Vl,
      v: this.cl,
    };
  }
  static qn(t) {
    return new ti(t.e, w(t.c), t.p, t.u, t.v);
  }
}
