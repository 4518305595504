export const MAX_PURCHASE_QUANTITY = 100;
export const MUST_BE_IN_APP_MESSAGE_WARNING =
  "inAppMessage must be an InAppMessage object";
export const MUST_BE_CARD_WARNING_SUFFIX = "must be a Card object";
export const FEED_ANIMATION_DURATION = 500;
export const LOG_CUSTOM_EVENT_STRING = "logCustomEvent";
export const SET_CUSTOM_USER_ATTRIBUTE_STRING = "setCustomUserAttribute";
export const BRAZE_MUST_BE_INITIALIZED_ERROR =
  "Braze must be initialized before calling methods.";
export const GLOBAL_RATE_LIMIT_CAPACITY_DEFAULT = 30;
export const GLOBAL_RATE_LIMIT_REFILL_RATE_DEFAULT = 30;
export const LAST_REQUEST_TO_ENDPOINT_MS_AGO_DEFAULT = 72e5;
export const MAX_ERROR_RETRIES_CONTENT_CARDS = 3;
export const REQUEST_ATTEMPT_DEFAULT = 1;
