import { REQUEST_ATTEMPT_DEFAULT as Ae } from "../common/constants.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
const D = {
  Ps: {
    La: "data",
    Bs: "content_cards/sync",
    bi: "feature_flags/sync",
    Ir: "template",
  },
  Du: (t) => (null == t ? void 0 : t.j(o.C.lE)),
  sm: (t) => (null == t ? void 0 : t.j(o.C.SE)),
  nm: (t, e) => {
    null == t || t.k(o.C.lE, e);
  },
  um: (t, e) => {
    null == t || t.k(o.C.SE, e);
  },
  Ua: (t, e) => {
    if (!t || !e) return -1;
    const s = D.Du(t);
    if (null == s) return -1;
    const n = s[e];
    return null == n || isNaN(n) ? -1 : n;
  },
  Ha: (t, e) => {
    let s = Ae;
    if (!t || !e) return s;
    const n = D.sm(t);
    return null == n ? s : ((s = n[e]), null == s || isNaN(s) ? Ae : s);
  },
  _s: (t, e, s) => {
    if (!t || !e) return;
    let n = D.Du(t);
    null == n && (n = {}), (n[e] = s), D.nm(t, n);
  },
  Qs: (t, e, s) => {
    if (!t || !e) return;
    let n = D.sm(t);
    null == n && (n = {}), (n[e] = s), D.um(t, n);
  },
  Ys: (t, e) => {
    if (!t || !e) return;
    const s = D.Ha(t, e);
    D.Qs(t, e, s + 1);
  },
};
export default D;
