import e from "../managers/braze-instance.js";
import g from "./content-cards-provider-factory.js";
export function subscribeToContentCardsUpdates(r) {
  if (!e.X()) return;
  const t = g.rr(),
    n = t.Zs(r);
  if (!t.ps()) {
    const r = e.cr();
    if (r) {
      const n = r.mr(() => {
        t.xs();
      });
      n && t.vs(n);
    }
  }
  return n;
}
