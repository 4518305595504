export default class wt {
  constructor(t, s, i, h, l) {
    (this.triggerId = t),
      (this._r = s),
      (this.Mr = i),
      (this.Er = h),
      (this.Gr = l),
      (this.triggerId = t),
      (this._r = s),
      (this.Mr = i),
      (this.Er = h),
      (this.Gr = l);
  }
  static fromJson(t, s, i, h, l) {
    return null == t || null == t.trigger_id
      ? null
      : new wt(t.trigger_id, s, i, h, l);
  }
}
